import React, { useContext, useEffect, useState } from 'react'

import Page from '../../../common/Page/Page'
import PageSectionObject from '../../../common/Page/PageSectionObject'

import PhoenixContext from '../../../Phoenix/PhoenixContext.js'
import OrganizationController from '../../../Phoenix/Controller/OrganizationController';
import { Link } from 'react-router-dom';
import UserRow from '../../../UserRow/UserRow';

import TeamIcon from '../../../image/icon/TeamIcon.png'
import UserController from '../../../Phoenix/Controller/UserController'

import CheckInRow from './CheckInRow'

export default function TeamCheckInsPage(props: {
  loggedInUser: any,
  match: any
}) {
  const teamId = props.match.params.teamId;

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);
  const userController = new UserController(phoenix);

  const [loading, setLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [memberToReport, setMemberToReport] = useState<any>({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    async function load() {
      const response = await organizationController.getOrganization(teamId);
      if (response.body !== undefined && response.body.organization !== undefined) {
        setOrganization(response.body.organization);

        for (const member of response.body.organization.members) {
          if (!member.hasReportForThisWeek) continue
          const response = await userController.getReportsForCurrentWeek(member.id);
          const reportsForMember = response.body;
          const newObject = {...memberToReport};
          newObject[member.id] = reportsForMember;
          setMemberToReport(newObject);
        }
      } else {
        if (response.error !== undefined) {
          setError(response.error);
        } else {
          setError("Failed to load... something went wrong.")
        }
      }

      setLoading(false);
    };

    load();
    // eslint-disable-next-line 
  }, [teamId]);

  if (loading) return null;
  if (error !== null) {
    return <div className="container">
      {error}
    </div>
  }
  if (organization === null) {
    return <div className="container">
      <h2>Error</h2>
      <p>We couldn't find that team. Please try again, or contact support.</p>
      <Link to="/">Go Home</Link>
    </div>
  }

  const membersSize = organization.members.length;
  const childrenSize = organization.children.length;

  return <div className="container">
    <Page pageTitle={organization.name + " • All Check-Ins"}
      pageSubtitle={
        `${membersSize} Member${membersSize === 1 ? "" : "s"}${childrenSize !== 0 ? " • " + childrenSize + " Department" + (childrenSize === 1 ? "" : "s") : ""}`
      }
      showBackButton={true}
      sections={[
        new PageSectionObject("Team Members", TeamIcon, organization.members, [],
          (member: any, searchText: string) => {
            if (searchText === undefined) return true;
            return member.name.toLowerCase().includes(searchText.toLowerCase());
          }, (user: any): any => {
            const loadingReports = memberToReport[user.id] === undefined;

            return <div key={user.id}>
              <UserRow user={user} />
              {user.hasReportForThisWeek ? <div>
                {loadingReports ? null : <div>
                    {memberToReport[user.id].reports.map((report: any) => {
                      return <CheckInRow key={report.id} checkIn={report} />
                    })}
                  </div>}    
              </div> : <p>{user.name} hasn't submitted a check-in for this week.</p>}
            </div>
          })
      ]} />
  </div>
}