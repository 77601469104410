import React from 'react';

import './Style/EditContextStyle.css';

export default class EditContext extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: props.user
    }
  }

  render() {
    return <div id="userEditContext">
      <div className="field">
        <h4>Name</h4>
        <input type="text"
            className="form-control"
            id={"name"}
            placeholder="Name"
            value={this.state.user.name || ""}
            onChange={this.onInputChange.bind(this)} />
      </div>

      <div className="field">
        <h4>Email</h4>
        <input type="text"
            className="form-control"
            id={"email"}
            placeholder="Email"
            value={this.state.user.email || ""}
            onChange={this.onInputChange.bind(this)} />
      </div>

      <div className="field">
        <h4>Role</h4>
        <input type="text"
            className="form-control"
            id={"role"}
            placeholder="Role"
            value={this.state.user.role || ""}
            onChange={this.onInputChange.bind(this)} />
      </div>

      <div className="field">
        <h4>Reports To</h4>
        <select id={"reportsTo"}
            className="form-control"
            value={this.state.user.reportsTo}
            onChange={this.onInputChange.bind(this)}
            style={{marginTop: 10}}>
            <option value="-1">Nobody</option>

            {this.props.reportOptions.map((user) => {
                return <option value={user.id} key={user.id}>{user.name}</option>
            })}
        </select>
      </div>

      <div className="field">
        <h4>Team</h4>
        <select id={"team"}
            className="form-control"
            value={this.props.user.team}
            onChange={this.onInputChange.bind(this)}
            style={{marginTop: 10}}>
            {this.props.teamOptions.map((team) => {
                return <option value={team.id} key={team.id}>{team.name}</option>
            })}
        </select>
      </div>

      <div className="field">
        <h4>Permission Level</h4>
        <select id="permissionLevel"
          value={this.props.user.isGlobalAdmin}
          className="form-control"
          onChange={this.onInputChange.bind(this)}>
          <option value="true">Global Administrator</option>
          <option value="false">No Permissions</option>
        </select>
      </div>
    </div>
  }

  onInputChange(e) {
    const id = e.target.id;
    const value = e.target.value;
    const currentState = this.state;

    switch (id) {
      case "name":
        currentState.user.name = value;
        break;
      case "email":
        currentState.user.email = value;
        break;
      case "role":
        currentState.user.role = value;
        break;
      case "reportsTo":
        currentState.user.reportsTo = value;
        break;
      case "team":
        currentState.user.team = value;
        break;
      case "permissionLevel":
        currentState.user.isGlobalAdmin = value === "true";
        break;
      default: return;
    }

    this.setState(currentState);
  }
}
