import React, { useContext, useEffect, useRef, useState } from 'react'
import GlobalAdminCheckmark from '../../GlobalAdminCheckmark.js';

import { Modal } from 'react-bootstrap';

import InfoContext from './InfoContext.js';
import EditContext from './EditContext.js';

import { Link, useHistory } from 'react-router-dom';

import './Style/UserDetailPageStyle.css';

import UserRow from '../../UserRow/UserRow';

import backButtonImage from '../../backbutton.png';
import PhoenixContext from '../../Phoenix/PhoenixContext';
import UserController from '../../Phoenix/Controller/UserController';
import OrganizationController from '../../Phoenix/Controller/OrganizationController';

export default function UserDetailPage(props) {
  const history = useHistory();
  const userId = props.match.params.userId;

  const [error, setError] = useState(null);
  const [fatalError, setFatalError] = useState(null);

  const [user, setUser] = useState(null);
  const [editUser, setEditUser] = useState(null); // This is a copy of the user, used to rollback changes if they cancel

  const [message, setMessage] = useState(null);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userTeam, setUserTeam] = useState("...")
  const [reportsTo, setReportsTo] = useState("...")

  const [reportOptions, setReportOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [deletingUser, setDeletingUser] = useState(false);

  const phoenix = useContext(PhoenixContext);
  const userController = new UserController(phoenix);
  const organizationController = new OrganizationController(phoenix);

  async function loadUser() {
    setUser(null);

    const response = await userController.getUser(userId);
    if (response.body !== undefined) {
      const user = response.body.user;
      setUser(user)
      await loadOrgTree(user.team);
      await loadUserTree(user.reportsTo)
    } else {
      setFatalError(response.error)
    }
  }

  async function loadOrgTree(userTeamId) {
    const response = await organizationController.getOrgTree();
    if (response.body === undefined) {
      return console.warn("Failed to load org tree")
    }

    const orgTree = response.body.orgTree;

    setTeamOptions(
      organizationController.convertOrgTreeToTeamOptionsList(orgTree)
    );

    function findUserTeam(team) {
      if (team.id === userTeamId) {
        return team.name;
      }

      let name = null;

      for (const department of team.children) {
        if (department.id === userTeamId) {
          name = department.name;
          break;
        } else {
          const subResult = findUserTeam(department);
          if (subResult !== null) {
            name = subResult;
          }
        }
      }

      return name;
    }

    setUserTeam(findUserTeam(orgTree) || "Unknown team")
  }

  async function loadUserTree(userManagerId) {
    const response = await userController.getUserTree();
    if (response.body === undefined) {
      return console.warn("Failed to load user tree")
    }

    const topUsers = response.body.topUsers;
    const managerOptions = userController.convertUserTreeToOptionsList(topUsers);
    setReportOptions(managerOptions);

    if (userManagerId === -1 || userManagerId === null) {
      setReportsTo("Nobody")
      return;
    }

    function findUserManager(user) {
      if (user.id === userManagerId) {
        setReportsTo(user.name);
        return true;
      }

      for (const report of user.directReports) {
        if (findUserManager(report)) break;
      }
    }

    for (const topUser of topUsers) {
      findUserManager(topUser);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    loadUser();
    // eslint-disable-next-line
  }, [userId]);

  async function saveEditsToUser() {
    if (saving) return;

    setSaving(true);
    setMessage("Saving...");
    setError(null);

    const response = await userController.editUser(editUser);
    setSaving(false);

    if (response.error !== undefined) {
      setError(response.error);
      setMessage(null);
      return;
    }

    const { user } = response.body;
    setUser(user);
    setMessage(`Saved changes to ${user.name}`);
    setEditing(false);
    setEditUser(null);

    setReportsTo("...")
    setUserTeam("...")

    loadOrgTree(user.team)
    loadUserTree(user.reportsTo)

    setTimeout(() => {
      setMessage(null);
    }, 3000);
  }

  if (!props.loggedInUser.isGlobalAdmin) {
    return <div className="container">
      <h3>You don't have permission to view this page. <a href="/">Click here to go home.</a></h3>
    </div>
  }

  if (fatalError !== null) {
    return <div className="container">
      <h3>Error: {fatalError}</h3>
    </div>
  }

  if (user === null) {
    return <div className="container">
      <h6>Loading...</h6>
    </div>
  }

  return <div className="container">
    {!editing ? <Link to={"/admin/team/" + user.team}
      style={{
        fontWeight: 650
      }}>
      <img src={backButtonImage} alt="Back Button" style={{ height: 20, marginRight: 5 }} />Back to {userTeam}
    </Link> : null}

    <div className="container-floating" style={{ marginTop: 20 }}>
      <div className="media">
        <img className="mr-3" src={user.pictureUrl || "../../defaultProfile.png"} alt={user.name} style={{ width: 100, height: 100, borderRadius: 50, objectFit: 'cover' }} />
        <div className="media-body">
          <h3 className="mt-0" style={{ fontWeight: 650, marginBottom: 0, paddingTop: 12 }}>{user.name} <GlobalAdminCheckmark user={user} /></h3>
          <h4>{user.directReports.count === 0 ? "No Direct Reports." : user.directReports.length + " Direct Reports"}</h4>
        </div>
      </div>

      {error !== null ? <p className="danger">{error}</p> : null}
      {message !== null ? <p className="message-box" style={{
        marginTop: 20,
        color: 'white',
        fontSize: 20
      }}>{message}</p> : null}

      <br />

      {editing ?
        <EditContext
          user={editUser}
          reportOptions={reportOptions}
          teamOptions={teamOptions}
        /> :
        <InfoContext
          user={user}
          reportsTo={reportsTo}
          userTeam={userTeam}
        />
      }

      <ActionButtons user={user}
        editUser={editUser}
        setShowDeleteModal={setShowDeleteModal}
        setEditing={(val) => {
          if (val) {
            setEditUser({ ...user })
            setEditing(true)
          } else {
            setEditUser(null)
            setEditing(false)
          }
        }}
        editing={editing}
        saving={saving}
        saveUser={() => {
          saveEditsToUser();
        }}
        setUser={setUser}
        setError={setError}
        userController={userController} />
    </div>

    <br />

    {editing || user.directReports.length === 0 ? null : <DirectReports user={user} />}

    <Modal show={showDeleteModal}>
      <Modal.Header>
        <h4 className="modal-title">Delete User?</h4>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete {user.name}'s account?</p>
      </Modal.Body>

      <div className="button-holder buttonGroup" style={{ gridTemplateColumns: "auto auto" }}>
        <button type="button"
          className="warning-severe"
          style={{ paddingTop: 0, paddingBottom: 0, borderRadius: 5 }}
          disabled={deletingUser}
          onClick={async (e) => {
            e.preventDefault();

            if (deletingUser) return;
            setDeletingUser(true)

            const response = await userController.deleteUser(user);
            if (response.error !== undefined) {
              alert(response.error || "Something went wrong. Please try again later.");
              window.location.reload();
              return;
            }

            alert("User deleted.")
            history.push(`/admin/team/${user.team}`)
          }}>Delete</button>
        <button type="button"
          style={{ paddingTop: 0, paddingBottom: 0, borderRadius: 5 }}
          disabled={deletingUser}
          onClick={(e) => {
            e.preventDefault();
            if (deletingUser) return;

            setShowDeleteModal(false)
          }}>Cancel</button>
      </div>
    </Modal>
  </div>
}

function ActionButtons(props) {
  const profilePictureUploader = useRef();

  const changeImage = (e) => {
    profilePictureUploader.current.click();
  }

  const imageSelected = (e) => {
    document.getElementById("changePictureButton").innerHTML = "Uploading..."
    document.getElementById("changePictureButton").disabled = true;

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    uploadProfilePicture(formData);
  }

  const uploadProfilePicture = async (formData) => {
    const response = await props.userController.uploadProfilePicture(props.user.id, formData);

    document.getElementById("changePictureButton").innerHTML = "Change Picture"
    document.getElementById("changePictureButton").disabled = false;

    if (response.error !== undefined) {
      return props.setError(response.error);
    }

    const newUser = response.body.user;
    props.setUser(newUser);
  }

  if (props.editing) {
    return <div className="buttonGroup">
      <button disabled={props.saving} onClick={(e) => {
        e.preventDefault();
        if (props.saving) return;

        props.saveUser();
      }}>Save Changes</button>
      <button className="warning" disabled={props.saving} onClick={(e) => {
        e.preventDefault();
        if (props.saving) return;

        props.setEditing(false)
      }}>Cancel</button>
      <button className="warning-severe" disabled={props.saving} onClick={(e) => {
        e.preventDefault();
        if (props.saving) return;

        props.setShowDeleteModal(true);
      }}>Delete User</button>
    </div>
  } else {
    return <div className="buttonGroup">
      <button className="success" id="changePictureButton" onClick={changeImage}>Change Picture</button>
      <button onClick={(e) => {
        e.preventDefault();

        props.setEditing(true);
      }}>Edit User</button>

      <form>
        <input type="file"
          name="file"
          ref={profilePictureUploader}
          style={{ display: "none" }}
          accept="image/*"
          onChange={imageSelected} />
      </form>
    </div>
  }
}

function DirectReports(props) {
  const [search, setSearch] = useState("");

  let directReports = props.user.directReportsDetailed;
  if (search.length !== 0) {
    directReports = directReports.filter((report) => {
      return report.name.toLowerCase().includes(search.toLowerCase());
    })
  }

  return <div id="directReports">
    <div id="directReportsHeader">
      <h2>Direct Reports</h2>
      <input type="text" placeholder="Search" onChange={(e) => {
        setSearch(e.target.value)
      }} />
    </div>

    {directReports.map((report) => {
      return <UserRow user={report} isAdminContext={true} key={report.id} />
    })}
  </div>
}