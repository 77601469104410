let sendRequest = undefined;

const configure = (config) => {
    sendRequest = config.sendRequest;
};

let promises = {};
function existingPromiseWithKey(key) {
    return promises[key];
}

function setPromiseForKey(key, value) {
    promises[key] = value;
    return value;
}

/**
@description This function retrieves the top level goals for the currently logged in user.
@returns An array of goals
*/
const getTopLevelGoals = () => {
    const key = "getTopLevelGoals";

    const existingPromise = existingPromiseWithKey(key);
    if (existingPromise !== null && existingPromise !== undefined) {
        return existingPromise;
    }

    return setPromiseForKey(key, new Promise((resolve, reject) => {
        sendRequest("GET", "/v1/goals/top-level-org-goals", {},
        (goals) => {
            setPromiseForKey(key, null);
            resolve(goals)
        }, (xhr, status, error) => {
            console.error(error);
            setPromiseForKey(key, null);
            reject(error);
        });
    }));
}

/**
@description This function retrieves the top level goals for the currently logged in user.
@returns An array of goals
*/
const getTree = (orgId) => {
    const key = "getTree";

    const existingPromise = existingPromiseWithKey(key);
    if (existingPromise !== null && existingPromise !== undefined) {
        return existingPromise;
    }

    return setPromiseForKey(key, new Promise((resolve, reject) => {
        sendRequest("GET", "/v1/organizations/tree/" + orgId, {},
        (tree) => {
            setPromiseForKey(key, null);
            resolve(tree)
        }, (xhr, status, error) => {
            console.error(error);
            setPromiseForKey(key, null);
            reject(error);
        });
    }));
}

const getAllSuborganizationsOfParent = (parentId, includeSelf, loadEntireTree) => {
    console.warn("⚠️ This should not be used.")
    console.trace("/children")

    return new Promise((resolve, reject) => {
        sendRequest("GET", "/v1/organizations/" + parentId + "/children", {includeSelf: includeSelf || false, loadEntireTree: loadEntireTree || false},
        (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            console.error(xhr);
            reject(error);
        })
    });
};

const getOrganization = (orgId, loadUserDirectReports, includeChildren) => {
    return new Promise((resolve, reject) => {
      if (loadUserDirectReports === undefined) {
        loadUserDirectReports = true;
      }

        sendRequest("GET", "/v1/organizations/" + orgId, {verbose: true, loadUserDirectReports: loadUserDirectReports, includeChildren: includeChildren},
        (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(xhr);
        })
    });
};

const editOrganization = (id, values) => {
    return new Promise((resolve, reject) => {
        sendRequest("PATCH", "/v1/organizations/" + id, {name: values.name, parent: values.parent, notes: values.notes},
        (organization) => {
            resolve(organization)
        }, (xhr, status, error) => {
            reject(xhr.responseJSON.error);
        })
    });
};

const addAdminTo = (id, adminId) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/organizations/" + id + "/administrators", {adminId: adminId},
        (organization) => {
            resolve(organization)
        }, (xhr, status, error) => {
            reject(xhr.responseJSON.error);
        })
    });
};

const removeAdminFrom = (id, adminId) => {
    return new Promise((resolve, reject) => {
        sendRequest("DELETE", "/v1/organizations/" + id + "/administrators", {adminId: adminId},
        (organization) => {
            resolve(organization)
        }, (xhr, status, error) => {
            reject(xhr.responseJSON.error);
        })
    });
};

const createOrganization = (values) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/organizations/", {name: values.name, parent: values.parent},
        (organization) => {
            resolve(organization)
        }, (xhr, status, error) => {
            reject(xhr.responseJSON.error);
        })
    });
};

export { configure, getTree, getTopLevelGoals, getAllSuborganizationsOfParent, editOrganization, addAdminTo, removeAdminFrom, createOrganization, getOrganization }
