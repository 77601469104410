import { Endpoint } from '../Endpoint';
import { HTTPMethod, HTTPRequest, Phoenix } from '../Phoenix';

export default class AuthenticationController {
  phoenix: Phoenix

  constructor($phoenix: Phoenix) {
    this.phoenix = $phoenix;
  }

  login(email: string, password: string): Promise<object> {
    return this.phoenix.perform(new HTTPRequest<object>(HTTPMethod.post, Endpoint.auth, `/login`, {
      email: email,
      password: password
    }));
  }

  loginUsingExistingSession(): Promise<object> {
    return this.phoenix.perform(new HTTPRequest<object>(HTTPMethod.post, Endpoint.auth, `/login`));
  }

  requestPasswordReset(email: string): Promise<object> {
    return this.phoenix.perform(new HTTPRequest<object>(HTTPMethod.post, Endpoint.auth, `/reset-password`, {
      email: email
    }));
  }

  confirmPasswordReset(password: string, resetKey: string): Promise<object> {
    return this.phoenix.perform(new HTTPRequest<object>(HTTPMethod.post, Endpoint.auth, `/confirm-reset-password/${resetKey}`, {
      password: password
    }))
  }

}