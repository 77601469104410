import React, { useContext } from 'react'
import PhoenixContext from '../../../../Phoenix/PhoenixContext'

export default function CheckInDetail(props: {}) {
  // eslint-disable-next-line
  const phoenix = useContext(PhoenixContext);

  return <div>
    
  </div>
}