import React, { useContext, useEffect, useState } from 'react';

import PhoenixContext from './Phoenix/PhoenixContext';
import AuthenticationController from './Phoenix/Controller/AuthenticationController';

import LoginComponent from './Login/LoginComponent';

import AdminContainer from './Admin/AdminContainer.js'
import OrganizationPage from './Admin/OrganizationPage.js'
import SetupWizard from './Admin/SetupWizard/SetupWizard.js'
import UserDetailPage from './Admin/UserDetailPage/UserDetailPage.js';
import CreateUserPage from './Admin/CreateUserPage/CreateUserPage'

import ConsumerContainer from './Consumer/ConsumerContainer.js'
import TeamPage from './Consumer/TeamPage/TeamPage'
import TeamCheckInsPage from './Consumer/TeamPage/CheckIns/TeamCheckInsPage'
import CheckInDetail from './Consumer/TeamPage/CheckIns/Detail/CheckInDetail'
import OrgTreePage from './Consumer/OrgTree/OrgTreePage.js'
import PasswordReset from './Login/PasswordReset.js'

import TermsOfService from './Legal/TermsOfService.js'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import GlobalAdminCheckmark from './GlobalAdminCheckmark.js';
import PrivacyPolicy from './Legal/PrivacyPolicy';

export default function ContainerComponent() {
  const phoenix = useContext(PhoenixContext);
  const authenticationController = new AuthenticationController(phoenix);

  const isLoggedInWithCookie = () => {
    return phoenix.hasSessionKey();
  };

  const shouldSkipToPasswordReset = () => {
    return window.location.pathname.split("/").length > 1 && window.location.pathname.split("/")[1] === "password-reset";
  }

  const [loading, setLoading] = useState(shouldSkipToPasswordReset() ? false : true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function login() {
      const response = await authenticationController.loginUsingExistingSession();
      
      if (response.body !== undefined && response.body.user !== undefined) {
        setUser(response.body.user);
      }

      setLoading(false);
    };

    login();
    // eslint-disable-next-line
  }, []);

  if (window.location.pathname === "/legal/terms-of-service") {
    return <TermsOfService />
  }

  if (window.location.pathname === "/legal/privacy-policy") {
    return <PrivacyPolicy />
  }

  if (loading) return null;

  if (!shouldSkipToPasswordReset() && (!isLoggedInWithCookie() && (user === null))) {
    return <LoginComponent setUser={(user) => {
      setUser(user);
      setLoading(false);
    }} />
  }

  if (user === null) {
    if (!shouldSkipToPasswordReset()) {
      window.alert("Something went wrong. Please log back in.");
      phoenix.logout();
      return null;
    }
  }

  return (<div>
    {shouldSkipToPasswordReset() || loading ? null : <nav className="navbar fixed-top bg flex-md-nowrap p-0 shadow" style={{ height: 70, backgroundColor: 'white' }}>
      <a className="navbar-brand mr-0" style={{ marginLeft: 20 }} href="../../../../../"><img src={process.env.PUBLIC_URL + "/MaxDark.png"} style={{ maxHeight: 20 }} alt="Logo" /></a>

      <ul className="navbar-nav px-3">
        <li className="nav-item text-nowrap">
          <GlobalAdminCheckmark user={user} message="You are a global administrator." />
          <p style={{ marginBottom: 0, marginTop: 5, display: 'inline-block' }}>{user ? user.name : null}</p>
          <a className="nav-link" href="#logout" onClick={(e) => {
            e.preventDefault();
            
            phoenix.logout();
            setUser(null);
            setLoading(false);
          }} style={{ paddingTop: 0, textAlign: 'right' }}><strong>Sign out</strong></a>
        </li>
      </ul>
    </nav>}

    {!phoenix.production ?
      <nav className="navbar bg flex-md-nowrap p-0 shadow" style={{ backgroundColor: 'orange', marginTop: 70 }}>
        <p style={{ paddingLeft: 10, paddingTop: 15, fontWeight: 650 }}><span role="img" aria-label="Warning Sign">⚠️</span> Running in development mode - {phoenix.getHostInfo()}</p>
      </nav> : null}

    {loading ? null : <div style={{ marginTop: phoenix.production ? 100 : 30 }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/"
              render={(props) => <ConsumerContainer user={user} loggedInUser={user} {...props} />} />
            <Route path="/user/:userId"
              render={(props) => <ConsumerContainer loggedInUser={user} {...props} />} />
            <Route path="/team/:teamId"
              render={(props) => <TeamPage loggedInUser={user} {...props} />} />
            <Route path="/tree/:teamId"
              render={(props) => <OrgTreePage loggedInUser={user} {...props} />} />
            <Route path="/team-check-ins/:teamId"
              render={(props) => <TeamCheckInsPage loggedInUser={user} {...props} />} />
            <Route path="/check-in/:checkInId"
              render={(props) => <CheckInDetail loggedInUser={user} {...props} />} />
            <Route path="/password-reset/:resetKey" component={PasswordReset} />
            <Route path="/admin/user/new"
              render={(props) => <CreateUserPage loggedInUser={user} {...props} />} />
            <Route path="/admin/user/:userId"
              render={(props) => <UserDetailPage loggedInUser={user} {...props} />} />
            <Route path="/admin/team/:teamId"
              render={(props) => <OrganizationPage loggedInUser={user} {...props} />} />
            <Route path="/admin/setup-wizard"
              render={(props) => <SetupWizard loggedInUser={user} {...props} />} />
            <Route path="/admin"
              render={(props) => <AdminContainer loggedInUser={user} {...props} />} />
            <Route render={() => {
              window.location = "/"
              return null;
            }} />
          </Switch>  
        </BrowserRouter>
    </div>}

    <br />
  </div>);

}
