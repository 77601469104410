import React from 'react';
import './App.css';

import './Modals.css';
import './SegmentedControl.css';
import './SearchHeader.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import ContainerComponent from './ContainerComponent.js';

import { phoenix, PhoenixProvider } from './Phoenix/PhoenixContext';

function App() {
  return (
    <div className="App">
      <PhoenixProvider value={phoenix}>
        <ContainerComponent />
      </PhoenixProvider>
    </div>
  );
}

export default App;
