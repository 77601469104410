import React from 'react'
import TaskList from './TaskList/TaskList.js'
import OrgGoalList from './OrgGoalList.js'
import DirectReports from './DirectReports.js'
import './index.css'

import { Link } from 'react-router-dom';

import paperclipImage from './paperclip.png';

import PersonalInfoModal from './PersonalInfoModal'

const MaxJS = require("../MaxJS/MaxJS.js");
const Goals = MaxJS.Goals;
const Users = MaxJS.Users;

export default class ConsumerContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = this.getDefaultState(props);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    if (props.user !== undefined) {
      this.loadData(props.user, true)
    } else if (this.state.userId !== undefined) {
      const userId = this.state.userId;
      this.loadUser(userId);
    } else {
      alert("Failed to load. Try again later.")
    }
  }

  getDefaultState(props) {
    return {
      loggedInUser: props.loggedInUser,
      user: props.user || null,
      userId: props.match.params.userId,
      tasks: [],
      dashboardOrganizations: [],
      dashboardLoaded: false,
      hoveredGoal: null,
      windowWidth: 1000,
      showingPersonalInfo: false
    };
  }

  componentDidUpdate(previousProps) {
    if (previousProps.match.params.userId !== this.props.match.params.userId) {
      this.setState(this.getDefaultState(this.props));
      this.loadUser(this.props.match.params.userId);
    }
  }

  async loadUser(id) {
    let user = await Users.getUser(id);
    await this.loadData(user, false);
  }

  async loadData(user, updateStateDirectly) {
    if (this.state.loggedInUser.id === user.id) {
      document.title = "Goals - My Tasks"
    } else {
      document.title = "Goals - " + user.name
    }

    if (updateStateDirectly) {
      this.state.tasks = user.tasks;
      this.state.user = user;
    } else {
      const currentState = this.state;
      currentState.tasks = user.tasks;
      currentState.user = user;
      this.setState(currentState);
    }

    try {
      const dashboardResults = await Goals.getDashboard();

      const currentState = this.state;
      currentState.needsGoals = dashboardResults.needsGoals;
      currentState.dashboardOrganizations = dashboardResults.organizations;
      currentState.dashboardLoaded = true;
      this.setState(currentState);

      console.log(dashboardResults);
    } catch (error) {
      console.error(error);
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const currentState = this.state
    currentState.windowWidth = window.innerWidth;
    this.setState(currentState)
  }

  onHoverEnter(task) {
    const currentState = this.state
    currentState.hoveredGoal = task.associatedGoal
    this.setState(currentState)
  }

  onHoverExit() {
    const currentState = this.state
    currentState.hoveredGoal = null
    this.setState(currentState)
  }

  render() {
    if (this.state.user === null) {
      return null;
    }

    return <div className="container">
      {this.state.dashboardLoaded && (this.state.user.isGlobalAdmin && this.state.needsGoals) ? <div className="alert alert-warning" role="alert">
        <Link to="/admin/setup-wizard" style={{ color: 'black' }}>Welcome to Business Goals! Click here to create your first users and goals. &rsaquo;</Link>
      </div> : null}

      {this.state.user.isGlobalAdmin ? <div style={{ marginBottom: 15 }}>
        <Link to={"/admin"}
          style={{
            fontWeight: 650,
          }}>
          <img src={paperclipImage} alt="Paperclip" style={{ height: 26, marginRight: 5 }} />Go to Administration Portal
                </Link>
      </div> : null}

      <div className="media" style={{ paddingBottom: 30 }}>
        <img className="mr-3"
          style={{ width: 60, height: 60, objectFit: 'cover', backgroundColor: '#D8D8D8', borderRadius: 30 }}
          src={this.state.user.pictureUrl || "../defaultProfile.png"}
          alt=""
          onError={(e) => { e.target.onerror = null; e.target.src = "../defaultProfile.png" }} />

        <div className="media-body">
          {this.state.userId !== undefined ?
            <div>
              <PersonalInfoModal user={this.state.user} 
                show={this.state.showingPersonalInfo} 
                hideCallback={() => {
                  this.setState({showingPersonalInfo: false})
                }} 
              />
              <h2 style={{ fontWeight: 700, marginBottom: 0 }}>{this.state.user.name}'s Tasks</h2>
              <p style={{ fontSize: 18, fontWeight: 600, display: 'inline-block' }}><Link to={"/" + this.state.user.id}>Back to My Tasks</Link> • <a href="#personal-info" style={{display: 'inline-block'}} onClick={() => {
                this.setState({showingPersonalInfo: true})
              }}>Personal Information</a></p>
            </div> :
            <div>
              <h2 style={{ fontWeight: 700, marginTop: 9 }}>My Tasks</h2>
            </div>
          }
        </div>
      </div>

      {this.state.windowWidth > 780 ? this.renderColumnLayout() : this.renderMobileLayout()}
    </div>
  }

  renderColumnLayout() {
    return <div className="row">
      <div className="col-7">
        <div style={{ maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
          <TaskList
            tasks={this.state.tasks}
            user={this.state.user}
            onHoverEnter={this.onHoverEnter.bind(this)}
            onHoverExit={this.onHoverExit.bind(this)} />

          <DirectReports
            user={this.state.user}
            loggedInUser={this.state.loggedInUser} />
        </div>
      </div>

      <div className="col-5">
        <OrgGoalList
          user={this.state.user}
          organizations={this.state.dashboardOrganizations}
          dashboardLoaded={this.state.dashboardLoaded}
          hoveredGoal={this.state.hoveredGoal}
          loggedInUser={this.state.loggedInUser} />
      </div>
    </div>
  }

  renderMobileLayout() {
    return <div>
      <TaskList
        tasks={this.state.tasks}
        user={this.state.user}
        onHoverEnter={this.onHoverEnter.bind(this)}
        onHoverExit={this.onHoverExit.bind(this)} />

      <DirectReports
        user={this.state.user}
        loggedInUser={this.state.loggedInUser} />

      <br />
      <br />

      <OrgGoalList
        user={this.state.user}
        organizations={this.state.dashboardOrganizations}
        dashboardLoaded={this.state.dashboardLoaded}
        hoveredGoal={this.state.hoveredGoal}
        loggedInUser={this.state.loggedInUser} />
    </div>
  }

}
