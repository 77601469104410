import React from 'react'
import { PageSectionAction } from './PageSectionObject';
import IconButton from '../IconButton/IconButton';

export default function PageHeader(props: { 
  onTextChange: (text: string) => void; 
  title: string; 
  searchPlaceholder?: string; 
  actions: PageSectionAction[],
}) {
  const onTextChange = props.onTextChange || ((_) => {
    console.error("[PageHeader.tsx]: onTextChange not passed to PageHeader component")
  });

  return <div className="pageHeader">
    <h2>{props.title || "Page Header Title"}</h2>
    <div className="actions">
      {props.actions.map((action) => {
        return <IconButton icon={action.icon} title={action.title} key={action.title} onClick={action.onClick} />
      })}
    </div>
    <input type="text" placeholder={props.searchPlaceholder || "Search"} onChange={(e) => {
      onTextChange(e.target.value);
    }} />
  </div>
}