let sendRequest = undefined;
let sendImageRequest = undefined;

const configure = (config) => {
    sendRequest = config.sendRequest;
    sendImageRequest = config.sendImageRequest;
}

const getAllTasksForUser = (userId) => {
    return new Promise((resolve, reject) => {
        sendRequest("GET", "/v1/users/" + userId, {},
        (user) => {
            resolve(user.tasks)
        }, (xhr, status, error) => {
            reject(error);
        })
    });
};

const createTask = (taskTitle, userId) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/tasks/", {title: taskTitle, ownerId: userId},
        (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error);
        })
    });
};

const editTask = (taskId, values) => {
    return new Promise((resolve, reject) => {
        sendRequest("PATCH", "/v1/tasks/" + taskId, {
            title: values.title,
            weight: values.weight,
            associatedGoal: values.goal,
            recurring: values.recurring,
            aboveLine: values.aboveLine !== undefined ? (values.aboveLine ? "1" : "0") : null,
            notes: values.notes,
            dueDate: values.dueDate,
            completed: values.completed !== undefined ? (values.completed ? "1" : "0") : null,
            priority: values.priority
        },
        (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error);
        })
    });
};

const deleteTask = (taskId) => {
    return new Promise((resolve, reject) => {
        sendRequest("DELETE", "/v1/tasks/" + taskId, {},
        (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error);
        })
    });
};

const deleteAttachment = (taskId, attachmentId) => {
    return new Promise((resolve, reject) => {
        sendRequest("DELETE", "/v1/tasks/attachment/" + taskId + "/" + attachmentId, {},
        (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error);
        })
    });
};

const addAttachment = (taskId, formData) => {
    return new Promise((resolve, reject) => {
        sendImageRequest("/v1/tasks/attachment/" + taskId, formData, (result) => {
            resolve(result);
        })
    });
}

export { configure, getAllTasksForUser, createTask, editTask, deleteTask, deleteAttachment, addAttachment }
