import './OrgChart.css'

var React = require('react');

export default function OrgChart(_ref) {
    const tree = _ref.tree;
    const canClick = _ref.canClick;
    const onClick = _ref.onClick;

    let fullWidth = _ref.fullWidth;

    if (fullWidth === undefined) {
        fullWidth = false;
    }

    function renderChildren(children, level) {
        return <ul className="sub-items" style={{marginTop: 10, paddingLeft: 0}}>
            {children.map((child) => {
                return renderChildElement(child, level)
            })}
        </ul>
    }

    function renderChildElement(child, level) {
        return <li key={child.id}>
            {renderChildDiv(child, level)}
            {renderChildren(child.children, level + 1)}
        </li>
    }

    function renderChildDiv(child, level) {

        return <div className="card-shadow"
             style={{
                 marginTop: 10,
                 marginLeft: level * 15,
                 marginRight: 5,
                 padding: 15,
                 paddingLeft: 20,
                 paddingRight: 20,
                 borderRadius: 6,
                 minWidth: 200
             }}>

            <h5
                style={{
                    margin: 0,
                    marginBottom: 0,
                    fontWeight: level === 0 ? 600 : 500,
                    fontSize: level === 0 ? "1.25rem" : "0.90rem",
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'left'
                }}>
                    {canClick === undefined || canClick(child) ?
                        <a href="#clickable"
                            style={{
                                color: 'black',
                                textDecoration: 'none'
                            }}
                            onClick={(e) => {
                                e.preventDefault();

                                if (canClick === undefined || canClick(child)) {
                                    onClick(child)
                                }
                            }}>
                            {child.name}
                        </a> :
                    <p>{child.name}</p>}
                </h5>

            <p style={{
                marginBottom: 0,
                fontWeight: level === 0 ? 500 : 400,
                fontSize: level === 0 ? "1.00rem" : "0.80rem",
                color: 'rgba(0, 0, 0, 0.5)'
            }}>{child.children === undefined ?
                "0 departments" :
                child.children.length + " department" + (child.children.length === 1 ? "" : "s")
            }</p>

        </div>
    }

    if (tree.children === undefined && tree.name === undefined) {
        // If the top level tree's children is undefined and the tree has no name, show loading
        // Likely that the tree is still loading in the parent component
        return <p style={{color: 'rgba(0, 0, 0, 0.5)'}}>Loading...</p>;
    }

    return <div className="orgchart" style={{
                                        width: fullWidth ? '100vw' : '',
                                        marginLeft: fullWidth ? "calc(-50vw + 50%)" : "",
                                        overflow: 'none',
                                        display: 'block',
                                    }}>
            <div style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '30%'}}>
                {renderChildDiv(tree, 0)}
            </div>

            <br/>

            {tree.children === undefined ? null : <div>
                <table style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        overflow: 'scroll'
                    }}>
                    <tbody>
                        <tr>
                            {tree.children.map((child) => {
                                return <td key={child.id} style={{
                                                            paddingTop: "0!important",
                                                            verticalAlign: "top"
                                                        }}>
                                    {renderChildDiv(child, 1)}
                                    {child.children !== 0 ? renderChildren(child.children, 2) : null}
                                </td>
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>}

        </div>
};
