import React from 'react'
import GoalView from './GoalView.js'

import CreateGoalModal from './TeamPage/CreateGoalModal.js'
import OrgNotesModal from './OrgNotesModal.js'

import NotesImage from './Notes128.png'
import AddImage from './Add128.png'

import FadeIn from '../FadeIn.js';

import { Link } from 'react-router-dom'

export default class OrgGoalList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedInUser: props.loggedInUser,
            user: props.user,
            shownModalId: null
        }
    }

    render() {
        if (!this.props.dashboardLoaded) {
            return null;
        }

        if (this.props.organizations.length === 0) {
            return this.renderAdminButtonIfApplicable()
        }

        return (<div>

            {this.props.organizations.map((organization, index) => {
                if (!organization.topLevel) {
                    return null;
                }

                return <FadeIn key={organization.id}>
                    <div>
                    <Link to={"/tree/" + organization.id}><h2 className="mt-0" style={{fontWeight: 600, color: 'black', marginBottom: 5}}>{organization.name}</h2></Link>
                        {organization.goals.map((goal, index) => {
                            return <p key={goal.id} style={{
                                    fontSize: 20,
                                    display: 'inline',
                                    marginBottom: 0,
                                    color: this.props.hoveredGoal === goal.id ? "black" : "rgba(0, 0, 0, 0.5)",
                                    fontWeight: this.props.hoveredGoal === goal.id ? "bold" : "400"
                                }}>{goal.title}{index !== organization.goals.length - 1 ? ", " : ""}</p>
                        })}

                        {organization.goals.length === 0 ?
                            <p style={{
                                fontSize: 20,
                                color: 'rgba(0, 0, 0, 0.5)'
                            }}>
                                <i>No Goals</i>
                            </p>
                            : <div><br/>
                            <div className="separator" style={{marginTop: '-5px', width: '50%'}}/>
                            <br/></div>}
                        </div>
                </FadeIn>
            })}

            {this.props.organizations.map((organization, index) => {
                if (organization.topLevel) {
                    return null;
                }

                const notesModalId = organization.id + "-notes";
                const createGoalModalId = organization.id + "-create-goal";

                return <FadeIn key={organization.id}><div key={organization.id} style={{marginTop: index === 0 ? 10 : 20}}>
                    <Link key={organization.id} to={"/team/" + organization.id} style={{display: 'inline-block'}}><h4 className="mt-0" style={{fontWeight: 600, color: 'black', marginBottom: 15}}>{organization.name}</h4></Link>

                    <CreateGoalModal organization={organization} loggedInUser={this.state.loggedInUser} show={this.state.shownModalId === createGoalModalId} />
                    <OrgNotesModal organization={organization} show={this.state.shownModalId === notesModalId} hideCallback={() => {
                        const currentState = this.state;
                        currentState.shownModalId = null;
                        this.setState(currentState);
                    }} loggedInUser={this.state.loggedInUser} />

                    <a href="#notes"
                        title="Edit Organization Notes"
                        onClick={(e) => {
                           e.preventDefault();

                           this.showModal(notesModalId);
                        }}>
                        <img src={NotesImage} alt="Edit Organization Notes" style={{
                            width: 24,
                            height: 24,
                            marginLeft: 12,
                            marginBottom: 8
                        }} onDragStart={(e) => e.preventDefault()} />
                    </a>

                        {/* TODO: Check if user is org admin */}
                    {this.state.loggedInUser.isGlobalAdmin ?
                        <a href="#add"
                            title="Create Goal"
                            onClick={(e) => {
                                e.preventDefault();
                                this.showModal(createGoalModalId);
                            }}>
                                <img src={AddImage} alt="Create Goal" style={{
                                    width: 24,
                                    height: 24,
                                    marginLeft: 12,
                                    marginBottom: 8
                                }} onDragStart={(e) => e.preventDefault()} />
                        </a> : null}

                        {organization.goals.map((goal, index) => {
                            return <GoalView goal={goal} key={goal.id} index={index} loggedInUser={this.state.loggedInUser} />
                        })}

                        {organization.goals.length === 0 ? <p style={{color: 'rgba(0, 0, 0, 0.5)'}}>No Goals</p> : null}
                </div></FadeIn>
            })}

            <div>
                <br/>
                <div className="separator" style={{marginTop: '5px', width: '50%'}}/>
                <br/>
            </div>
        </div>)
    }

    showModal(id) {
        const currentState = this.state;
        currentState.shownModalId = id;
        this.setState(currentState);
    }

}
