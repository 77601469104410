import React from 'react'
import { Modal } from 'react-bootstrap';

const Organizations = require("../MaxJS/MaxJS.js").Organizations;

export default class OrgNotesModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: props.organization.notes,
            hideCallback: props.hideCallback
        }
    }

    componentDidMount() {
        const currentState = this.state;
        currentState.notes = this.props.organization.notes;
        this.setState(currentState);
    }

    updateNotes(notes) {
        const currentState = this.state;
        currentState.notes = notes;
        this.setState(currentState)
    }

    hide() {
        this.state.hideCallback();
    }

    render() {
        return <Modal show={this.props.show}>
            <Modal.Header>
                <h4 className="modal-title">{this.props.organization.name} Notes</h4>
            </Modal.Header>

            <Modal.Body>
                {this.state.error === undefined ? null : <p className="error">{this.state.error}</p>}

                {/* TODO: Check to see if user is admin of org */}
                {this.props.loggedInUser.isGlobalAdmin ? <form>
                    <div className="form-group">
                        <textarea className="form-control" id={"onm-name-" + this.props.organization.id} placeholder="Notes..." defaultValue={this.state.notes} style={{minHeight: 200}} onChange={(e) => {
                            this.updateNotes(e.target.value);
                        }} />
                    </div>
                </form> : <p>{this.props.organization.notes || "No notes."}</p>}
            </Modal.Body>

            <div className="button-holder" style={{gridTemplateColumns: "auto auto"}}>
                <button type="button" className="btn" data-dismiss="modal" onClick={(e) => {
                    this.hide();
                }} style={{backgroundColor: '#5A5A5A'}}>Cancel</button>
                <button type="button" className="btn" style={{backgroundColor: '#167efb'}} onClick={this.onSubmitClicked.bind(this)}>Save</button>
            </div>
            </Modal>
    }

    onSubmitClicked(e) {
        e.preventDefault();

        if (this.state.notes === null) {
            this.hide();
            return;
        }

        this.props.organization.notes = this.state.newNotes;

        Organizations.editOrganization(this.props.organization.id, {notes: this.state.notes}).then(() => {
            // success
        }).catch((error) => {
            console.error(error);
        });

        this.hide();
    }

}
