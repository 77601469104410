import { Endpoint } from '../Endpoint';
import { HTTPMethod, HTTPRequest, HTTPResponse, Phoenix } from '../Phoenix';

export default class OrganizationController {
  phoenix: Phoenix

  constructor($phoenix: Phoenix) {
    this.phoenix = $phoenix;
  }

  getOrganization(id: number): Promise<HTTPResponse<any>> {
    return this.phoenix.perform(new HTTPRequest<object>(HTTPMethod.get, Endpoint.organizations, `/${id}`));
  }

  getOrgTree(): Promise<HTTPResponse<any>> {
    return this.phoenix.perform(new HTTPRequest<object>(HTTPMethod.get, Endpoint.organizations, `/orgtree`));
  }

  createOrganization(
    name: string,
    parent: number
  ): Promise<HTTPResponse<any>> {
    return this.phoenix.perform(
      new HTTPRequest<object>(HTTPMethod.post, Endpoint.organizations, ``, {
        name: name,
        parent: parent
      })
    )
  }

  convertOrgTreeToTeamOptionsList(orgTree: any): any[] {
    let options: any[] = [];

    const add = (organization: any, level: number) => {
      let dashesString = "";

      for (let i = 0; i < level; i++) {
        dashesString += "-"
      }

      options.push({ id: organization.id, name: dashesString + organization.name })

      if (organization.children !== undefined && organization.children.length !== 0) {
        for (const child of organization.children) {
          add(child, level + 1)
        }
      }
    }

    if (orgTree !== null && orgTree !== undefined) {
      add(orgTree, 0);
    }

    return options
  }

}