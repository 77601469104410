import React from 'react';
import './Style/InfoContextStyle.css';

import { Link } from 'react-router-dom';

export default class InfoContext extends React.Component {
  render() {
    return <div id="userInfoContext">
      <div className="infoField">
        <h4>Name</h4>
        <p>{this.props.user.name}</p>
      </div>

      <div className="infoField">
        <h4>Email</h4>
        <a href={"mailto:" + this.props.user.email}>{this.props.user.email}</a>
      </div>

      <div className="infoField">
        <h4>Role</h4>
        <p>{this.props.user.role || "No role"}</p>
      </div>

      <div className="infoField">
        <h4>Reports To</h4>
        {this.props.user.reportsTo !== -1 ?
          <Link to={"/admin/user/" + this.props.user.reportsTo}>
            <p>{this.props.reportsTo}</p>
          </Link> :
          <p>{this.props.reportsTo}</p>
        }
      </div>

      <div className="infoField">
        <h4>Team</h4>
        {this.props.user.team !== -1 ?
          <Link to={"/admin/team/" + this.props.user.team}>
            <p>{this.props.userTeam}</p>
          </Link> :
          <p>{this.props.userTeam}</p>
        }
      </div>

      <div className="infoField">
        <h4>Permission Level</h4>
        <p>{this.props.user.isGlobalAdmin ? "Global Administrator" : "No permissions."}</p>
      </div>
    </div>
  }
}
