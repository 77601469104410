let sendRequest = undefined;

const configure = (config) => {
    sendRequest = config.sendRequest;
}

const getDashboard = () => {
    return new Promise((resolve, reject) => {
        sendRequest("GET", "/v1/goals/dashboard", {}, (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error)
        });
    });
};

const deleteGoal = (goalId) => {
    return new Promise((resolve, reject) => {
        sendRequest("DELETE", "/v1/goals/" + goalId, {}, (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error)
        })
    })
}

const createGoal = (title, parentGoalId, orgId) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/goals/", {title: title, parent: parentGoalId, organization: orgId}, (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error)
        })
    })
}

const editGoal = (goalId, values) => {
    return new Promise((resolve, reject) => {
        sendRequest("PATCH", "/v1/goals/" + goalId, {title: values.title, parent: values.parent, notes: values.notes}, (result) => {
            resolve(result)
        }, (xhr, status, error) => {
            reject(error)
        })
    })
};

export { configure, getDashboard, deleteGoal, createGoal, editGoal }
