import React from 'react'
import FadeIn from '../FadeIn.js'

import UserRow from '../UserRow/UserRow';

export default class DirectReports extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            user: props.user || null,
            loggedInUser: props.loggedInUser
        }
    }

    render() {
        if (this.state.user === null) return null;

        let directReports;
        if (this.state.user.directReportsDetailed !== undefined) {
            directReports = this.state.user.directReportsDetailed;
        } else {
            directReports = this.state.user.directReports;
        }

        if (directReports.length === 0) return null;

        return <div style={{marginTop: 50}}>
            <FadeIn>
                <h3 style={{paddingBottom: 10, fontWeight: 'bold'}}>Direct Reports</h3>
                {directReports.map((report) => {
                    return <UserRow user={report} key={report.id} />
                })}
            </FadeIn>
        </div>
    }

}
