import React from 'react'

import { Modal } from 'react-bootstrap';

const Organizations = require("../../MaxJS/MaxJS.js").Organizations;
const Goals = require("../../MaxJS/MaxJS.js").Goals;

export default class CreateGoalModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loggedInUser: props.loggedInUser,
            topLevelGoals: [],
            newGoalParentId: null,
            newGoalName: "",
            organization: props.organization
        }

        Organizations.getTopLevelGoals().then((goals) => {
            let currentState = this.state
            currentState.topLevelGoals = goals;
            this.setState(currentState)
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        return <Modal show={this.props.show}>
                <Modal.Header>
                    <h4 className="modal-title">New {this.state.organization.name} Goal</h4>
                </Modal.Header>

                <Modal.Body>
                    {this.state.error === undefined ? null : <p className="error">{this.state.error}</p>}

                    <form>
                        <div className="form-group">
                            <label htmlFor="oem-goal-name">Goal Name</label>
                            <input type="text" className="form-control" id="ngm-name" placeholder="Goal Name" onChange={(e) => {
                                const currentState = this.state
                                currentState.newGoalName = e.target.value
                                this.setState(currentState)
                            }} />
                        </div>

                        {this.state.organization.topLevel ? null : <div className="form-group">
                            <label>Parent Goal</label>
                            {this.renderGoalSelect()}
                        </div>}
                    </form>
                </Modal.Body>

                <div className="button-holder" style={{gridTemplateColumns: "auto auto"}}>
                    <button type="button" className="btn" onClick={(e) => window.location.reload()} style={{backgroundColor: '#5A5A5A'}}>Cancel</button>
                    <button type="button" className="btn" style={{backgroundColor: '#167efb'}} onClick={this.onSubmitClicked.bind(this)}>Save Goal</button>
                </div>
            </Modal>
    }

    renderGoalSelect() {
        let options = [];

        for (const goal of this.state.topLevelGoals) {
            options.push(<option value={goal.id} key={goal.id}>{goal.title}</option>)
        }

        return <select id="ngm-parentgoal" className="form-control" value={this.state.parent} onChange={(e) => {
            const currentState = this.state
            currentState.newGoalParentId = e.target.value
            this.setState(currentState)
        }}>
            {options}
        </select>
    }

    onSubmitClicked(e) {
        e.preventDefault()

        Goals.createGoal(this.state.newGoalName, this.state.newGoalParentId, this.state.organization.id).then((goal) => {
            window.location.reload()
        }).catch((error) => {
            const currentState = this.state
            currentState.error = error
            this.setState(currentState)
        })

        return
    }

}
