import React from 'react'

import NotFinished from './NotFinished128.png';
import Finished from './Finished128.png';

import EditTaskModal from './Modal/EditTaskModal.js'

const Tasks = require("../../MaxJS/MaxJS").Tasks;

export default class TaskRow extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            task: props.task,
            user: props.taskOwner,
            onHoverEnter: props.onHoverEnter,
            onHoverExit: props.onHoverExit,
            onCompleted: props.onCompleted,
            showModal: false
        }
    }

    render() {
        let subtitleString = "";

        if (this.state.task.completed) {
            const dateString = new Date(this.state.task.completionDate).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})

            subtitleString = "Completed on " + dateString + (this.state.task.weight === 0 ? "" : " • " + this.state.task.weight + "%")
        } else {
            const dateString = new Date(this.state.task.dueDate).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})

            subtitleString = "Due " + dateString + (this.state.task.weight === 0 ? "" : " • " + this.state.task.weight + "%")
        }

        return <div>

                    <div className="task card-shadow"
                        style={{minHeight: '80px'}}

                        onMouseEnter={() => {
                            this.state.onHoverEnter(this.state.task)
                        }} onMouseLeave={() => {
                            this.state.onHoverExit(this.state.task)
                        }}>

                    <div className="media">
                        <div>
                            <div className={"priorityLabel " + (this.state.task.aboveLine ? "aboveLine" : "belowLine")}>
                                <p style={this.state.task.priority >= 10 ? {left: "15%"} : {}}>
                                        {this.state.task.aboveLine ? "A" : "B"}{this.state.task.priority}
                                    </p>
                            </div>
                        </div>
                        <div className="media-body">
                            <a href="#edit-task"
                                onClick={(e) => {
                                    const currentState = this.state;
                                    currentState.showModal = true;
                                    this.setState(currentState);
                                }}
                                style={{textDecoration: 'none'}}
                                onDragStart={(e) => e.preventDefault()}>
                                <h5 style={{fontWeight: 600, color: 'black'}}>{this.state.task.title}</h5>
                                <h6 style={{color: 'rgba(0, 0, 0, 0.5)'}}>{subtitleString}</h6>
                            </a>
                        </div>
                        <a href={this.state.task.completed ? "#markAsUncompleted" : "#markAsCompleted"}
                            onClick={(e) => {
                                e.preventDefault();

                                Tasks.editTask(this.state.task.id, {completed: !this.state.task.completed})
                                this.state.onCompleted(this.state.task)
                            }}>
                            <img src={this.state.task.completed ? Finished : NotFinished}
                                width="30"
                                height="30"
                                alt={this.state.task.completed ? "Mark as Uncompleted" : "Mark as Completed"}
                                style={{marginTop: 17}}
                                onDragStart={(e) => e.preventDefault()} />
                        </a>
                    </div>
            </div>

            <EditTaskModal user={this.state.user} task={this.state.task} show={this.state.showModal} />
        </div>
    }

}
