let sendRequest = undefined;
let sendImageRequest = undefined;

const configure = (config) => {
  sendRequest = config.sendRequest;
  sendImageRequest = config.sendImageRequest;
}

const getAllUsers = () => {
  return new Promise((resolve, reject) => {
    sendRequest("GET", "/v1/users/", {},
    (users) => {
      resolve(users)
    }, (xhr, status, error) => {
      reject(error);
    })
  });
};

const getUser = (userId) => {
  return new Promise((resolve, reject) => {
    sendRequest("GET", "/v1/users/" + userId, {},
    (user) => {
      resolve(user)
    }, (xhr, status, error) => {
      reject(xhr.responseJSON || error);
    })
  });
};

const editUser = (id, values) => {
  return new Promise((resolve, reject) => {
    sendRequest("PATCH", "/v1/users/" + id, {name: values.name, email: values.email, role: values.role, isGlobalAdmin: values.isGlobalAdmin, team: values.team, reportsTo: values.reportsTo},
    (user) => {
      resolve(user)
    }, (xhr, status, error) => {
      reject(xhr.responseJSON.error);
    })
  });
};

const uploadUserProfilePicture = (id, formData) => {
  return new Promise((resolve, reject) => {
    sendImageRequest("/v1/users/picture/" + id, formData, (user) => {
      resolve(user.pictureUrl);
    })
  });
}

const createUser = (values) => {
  return new Promise((resolve, reject) => {
    sendRequest("POST", "/v1/users/", {name: values.name, email: values.email, role: values.role, team: values.team, reportsTo: values.reportsTo},
    (user) => {
      resolve(user)
    }, (xhr, status, error) => {
      reject(xhr.responseJSON.error);
    })
  });
};

const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    sendRequest("DELETE", "/v1/users/" + id, {},
    () => {
      resolve()
    }, (xhr, status, error) => {
      reject(xhr.responseJSON.error);
    })
  });
};

const getUserTree = () => {
  return new Promise((resolve, reject) => {
    sendRequest("GET", "/v1/users/tree", {}, (users) => {
      resolve(users);
    }, (xhr, status, error) => {
      reject(xhr.responseJSON.error);
    })
  });
};

const generateDirectReportTree = (users, loggedInUser, level) => {
  let rows = [];

  for (let user of users) {
    if (loggedInUser === null || user.id !== loggedInUser.id) {
      let dashesString = "";

      for (let i = 0; i < level; i++) {
        dashesString += "-"
      }

      rows.push({user: user, display: dashesString + user.name.toUpperCase(), level: level});

      if (user.directReports.length !== 0) {
        rows = rows.concat(generateDirectReportTree(user.directReports, loggedInUser, level + 1));
      }
    }
  }

  return rows;
}

export { configure, getAllUsers, editUser, createUser, generateDirectReportTree, uploadUserProfilePicture, getUser, deleteUser, getUserTree }
