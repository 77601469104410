import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap';

import OrganizationController from '../../../Phoenix/Controller/OrganizationController';
import PhoenixContext from '../../../Phoenix/PhoenixContext';

export default function CreateOrganizationModal(props) {
  const {
    loggedInUser,
    orgTree,
    show,
    hide,
    onRequireOrgTreeRefresh
  } = props;

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const [creating, setCreating] = useState(false);

  const [orgOptions, setOrgOptions] = useState([]);

  const [parent, setParent] = useState(loggedInUser.topLevelOrg);
  const [name, setName] = useState("");

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);

  useEffect(() => {
    let options = [];

    function add(organization, level) {
      let dashesString = "";

      for (let i = 0; i < level; i++) {
        dashesString += "-"
      }

      options.push({ id: organization.id, name: dashesString + organization.name })

      if (organization.children !== undefined && organization.children.length !== 0) {
        for (const child of organization.children) {
          add(child, level + 1)
        }
      }
    }

    if (orgTree !== null && orgTree !== undefined) {
      add(orgTree, 0);
    }

    setOrgOptions(options);
  }, [orgTree]);

  const onCreateClicked = async(e) => {
    e.preventDefault();

    setCreating(true);

    const response = await organizationController.createOrganization(name, parent);
    if (response.error !== undefined) {
      setError(response.error)
    } else {
      setMessage(response.message || "Organization created.");

      setName("");
      setParent(loggedInUser.topLevelOrg);

      onRequireOrgTreeRefresh();
    }

    setCreating(false);
  }

  const resetAlerts = () => {
    setError(null)
    setMessage(null)
  }

  return <Modal show={show}>
    <Modal.Header>
      <h4 className="modal-title">Create Department</h4>
    </Modal.Header>

    <Modal.Body>
      {error === null ? null : <p className="error-box">{error}</p>}
      {message === null ? null : <p className="message-box">{message}</p>}

      <form>
        <div className="form-group">
          <label>Name</label>
          <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => {
            e.preventDefault();
            setName(e.target.value);

            resetAlerts();
          }} autoComplete="off" />
        </div>

        <div className="form-group">
          <label>Parent Department</label>
          <select className="form-control" value={parent} onChange={(e) => {
            e.preventDefault();
            setParent(e.target.value);

            resetAlerts();
          }}>
            {orgOptions.map((org) => {
              return <option value={org.id} key={org.id}>{org.name}</option>
            })}
          </select>
        </div>
      </form>
    </Modal.Body>

    <div className="button-holder" style={{ gridTemplateColumns: "auto auto" }}>
      <button type="button" className="btn" disabled={creating} onClick={(e) => {
        e.preventDefault();

        setName("");
        setParent(loggedInUser.topLevelOrg);

        hide();
      }} style={{ backgroundColor: '#5A5A5A' }}>Cancel</button>
      <button type="button" className="btn" disabled={creating} style={{ backgroundColor: '#167efb' }} onClick={onCreateClicked}>Save</button>
    </div>
  </Modal>
}
