import { Component } from "react";

export default class PageSectionObject {
  name: string
  icon: any
  content: any[]
  actions: PageSectionAction[];
  filterProvider: (object: any, searchText: string) => boolean;
  contentComponentProvider: (content: any) => Component | null;

  constructor(
    $name: string,
    $icon: any,
    $content: any[],
    $actions: PageSectionAction[],
    $filterProvider: (object: any, searchText: string) => boolean,
    $contentComponenetProvider: (content: any) => Component | null
  ) {
    this.name = $name;
    this.icon = $icon;
    this.content = $content;
    this.actions = $actions;
    this.filterProvider = $filterProvider;
    this.contentComponentProvider = $contentComponenetProvider;
  }

  toHTMLId(): string {
    return "#" + this.name.toLowerCase().replace(" ", "_")
  }
}

export class PageSectionAction {
  icon: any
  title: string
  onClick: () => void;

  constructor(
    $icon: any,
    $title: string,
    $onClick: () => void
  ) {
    this.icon = $icon;
    this.title = $title;
    this.onClick = $onClick;
  }
}