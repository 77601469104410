import React, { createRef, useEffect, useState } from 'react'
import PageSectionObject from './PageSectionObject'
import PageSection from './PageSection'
import FadeIn from 'react-fade-in'

import BackButton from '../../Back128.png'

import './Page.scss';

import useReactRouter from 'use-react-router';

export default function Page(props: {
  sections: PageSectionObject[]
  showBackButton: boolean,
  pageTitle: string,
  pageSubtitle?: string
}) {
  const { history } = useReactRouter();
  const sectionLength = props.sections.length;
  const defaultValue: any[] = [];
  const [componentToRef, setComponentToRef] = useState(defaultValue);

  useEffect(() => {
    setComponentToRef(current => {
      return Array(sectionLength).fill(null).map((_, i) => current[i] || createRef())
    });
  }, [sectionLength]);

  return <div className="page">
    <FadeIn>
      <ul>
        <li><a href="#back" onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}><img src={BackButton} alt="Back" /> Back</a></li>
        {props.sections.map((section, index) => {
          const isIconComponent = typeof section.icon === 'object';

          return <li key={section.name}>
            <a href={section.toHTMLId()} onClick={(e) => {
              e.preventDefault();
              const ref = componentToRef[index].current;
              const yOffset = -80;
              const y = ref.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({ top: y, behavior: "smooth" })
            }}>
              {isIconComponent ? section.icon : <img className="page" src={section.icon} alt={`${section.name} icon`} />} {section.name}
            </a>
          </li>
        })}
      </ul>
      <h1 className="title">{props.pageTitle}</h1>
      {props.pageSubtitle !== undefined ?
        <h2 className="subtitle">{props.pageSubtitle}</h2> :
        null}

      {props.sections.map((section, i) => {
        return <PageSection section={section} key={section.name} reference={componentToRef[i]} />
      })}
    </FadeIn>
  </div>
}