import React from 'react'

import Finished from '../TaskList/Finished128.png'
import NotFinished from '../TaskList/NotFinished128.png'

export default class SmallTaskRow extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            task: props.task,
            user: props.taskOwner
        }
    }

    render() {
        return <div>
                <div className="task card-shadow">
                    <div className="media">
                        <div>
                            <div className={"priorityLabel " + (this.state.task.aboveLine ? "aboveLine" : "belowLine")} >
                                <p style={this.state.task.priority >= 10 ? {left: "15%"} : {}}>
                                        {this.state.task.aboveLine ? "A" : "B"}{this.state.task.priority}
                                    </p>
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 style={{fontWeight: 600, color: 'black'}}>{this.state.task.title}</h5>
                        </div>
                        <img src={this.state.task.completed ? Finished : NotFinished}
                            width="30"
                            height="30"
                            alt={this.state.task.completed ? "Uncompleted" : "Completed"}
                            style={{marginTop: 7, marginRight: 7}}
                            onDragStart={(e) => e.preventDefault()} />
                    </div>
                </div>
        </div>
    }

}
