import React from 'react'
import NewExpectationButton from './NewExpectationButton.js'
import TaskRow from './TaskRow.js'
import TaskReprioritzeModal from './Modal/TaskReprioritzeModal.js'

import FadeIn from '../../FadeIn.js'

import Cookies from 'universal-cookie'
const cookies = new Cookies();

export default class TaskList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            tasks: props.tasks,
            currentTab: cookies.get("currentTab") || "active",
            onHoverEnter: props.onHoverEnter,
            onHoverExit: props.onHoverExit
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentState = this.state
        currentState.tasks = nextProps.tasks || []
        this.setState(currentState)
    }

    userTasks(showCompleted, aboveLine) {
        let tasks = this.state.tasks.filter((task) => {
            if (!showCompleted && task.completed) {
                return false
            } else if (showCompleted) {
                return task.aboveLine === aboveLine && task.completed
            } else {
                return task.aboveLine === aboveLine
            }
        })

        if (showCompleted) {
            return tasks.sort((a, b) => {
                return a.completionDate < b.completionDate
            })
        } else {
            return tasks.sort((a, b) => {
                return a.priorty - b.priorty
            })
        }
    }

    onCompleted(task) {
        const currentState = this.state

        let tasks = currentState.tasks

        tasks.forEach((userTask) => {
            if (userTask.aboveLine === task.aboveLine
                && userTask.priority > task.priority
                && userTask.completed !== task.completed) {
                userTask.priority -= 1
            }

            if (userTask.id === task.id) {
                userTask.completed = !userTask.completed

                if (userTask.completed) {
                    userTask.completionDate = new Date()
                }
            }
        })

        tasks = tasks.sort((a, b) => {
            if (a.completed && b.completed) {
                return a.completionDate < b.completionDate
            }

            return a.priority - b.priority
        })

        currentState.tasks = tasks
        this.setState(currentState)
    }

    onSegmentedControlChanged(e) {
        e.preventDefault();

        const parent = e.target.parentElement;
        const tab = parent.id.split("-")[2];

        const currentState = this.state
        currentState.currentTab = tab
        this.setState(currentState)

        cookies.set("userCurrentTab", tab, { path: '/' })
    }

    render() {
        const aboveLineTasks = this.userTasks(this.state.currentTab === "completed", true);
        const belowLineTasks = this.userTasks(this.state.currentTab === "completed", false);

        return (<div>
            <div className="segmented">
                <label id="consumer-seg-active" className={this.state.currentTab === "active" ? "checked" : ""}><input type="radio" name="segmented" checked onClick={this.onSegmentedControlChanged.bind(this)} readOnly /> Active Tasks</label>
                <label id="comsumer-seg-completed" className={this.state.currentTab === "completed" ? "checked" : ""}><input type="radio" name="segmented" onClick={this.onSegmentedControlChanged.bind(this)} readOnly /> Completed Tasks</label>
            </div>

            <br/>

            <NewExpectationButton userId={this.state.user.id} onTaskCreated={(task) => {
                const currentState = this.state

                let tasks = currentState.tasks

                tasks.forEach((userTask) => {
                    if (userTask.aboveLine === task.aboveLine) {
                        userTask.priority += 1
                    }
                })

                tasks.push(task)

                tasks = tasks.sort((a, b) => {
                    return a.priority - b.priority
                })

                currentState.tasks = tasks
                this.setState(currentState)
            }} />

            <br/>

            {this.state.tasks.length === 0 ? null : <FadeIn>
                {aboveLineTasks.map((task) => {
                    return <TaskRow
                        task={task}
                        taskOwner={this.state.user}
                        key={task.id}
                        onHoverEnter={this.state.onHoverEnter.bind(this)}
                        onHoverExit={this.state.onHoverExit.bind(this)}
                        onCompleted={this.onCompleted.bind(this)} />
                })}

                {aboveLineTasks.length !== 0 && belowLineTasks.length !== 0 ?
                    <div><br/>
                    <div className="separator" style={{marginTop: '-10px'}}/>
                    <br/></div> : null}

                {belowLineTasks.map((task) => {
                    return <TaskRow
                        task={task}
                        taskOwner={this.state.user}
                        key={task.id}
                        onHoverEnter={this.state.onHoverEnter.bind(this)}
                        onHoverExit={this.state.onHoverExit.bind(this)}
                        onCompleted={this.onCompleted.bind(this)} />
                })}

                {(aboveLineTasks.length > 1 || belowLineTasks.length > 1) && this.state.currentTab !== "completed" ? <div>
                <br/>
                <a href="#reprioritze-tasks"
                    id="reprioritzeDropdownMenuLink"
                    data-toggle="modal"
                    data-backdrop="static"
                    data-target="#taskReorderModal"
                    style={{
                        textAlign: 'center',
                        color: '#167efb',
                        fontWeight: 'bold',
                        display: 'block',
                        width: '100%',
                        boxShadow: 'none'
                    }}>Reprioritze Tasks</a>

                <TaskReprioritzeModal tasks={this.state.tasks} /></div> : null}
            </FadeIn>}
        </div>)
    }

}
