import React from 'react'
import UserReport from '../../../Phoenix/Controller/Model/UserReport'

import './CheckInRow.scss'

import LineFace from './faceImages/LineFace.svg'
import FrownFace from './faceImages/FrownFace.svg'
import FrownCryingFace from './faceImages/FrownCryingFace.svg'
import HappyFace from './faceImages/HappyFace.svg'
import SmileFace from './faceImages/SmileFace.svg'

export default function CheckInRow(props: {
  checkIn: UserReport
}) {
  let image: any;
  switch(props.checkIn.feelingStateInt) {
    case 1:
      image = FrownCryingFace;
      break;
    case 2:
      image = FrownFace;
      break;
    case 3:
      image = LineFace;
      break;
    case 4:
      image = HappyFace;
      break;
    case 5:
      image = SmileFace;
      break;
  }
  
  return <div className="userReport">
    <img src={image} alt={"Face"} />
    <p>
      {new Date(props.checkIn.reportDate).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      })}
    </p>
  </div>
}