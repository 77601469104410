import React from 'react'

const Login = require('../MaxJS/MaxJS.js').Login;

export default class PasswordReset extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            resetKey: props.match.params.resetKey || "",
            newPassword: "",
            confirmNewPassword: "",
            requesting: false
        }
    }

    render() {
        return <div style={{
            marginTop: -50
        }}>
            <img src="../MaxDark.png" className="logo" alt="Goals" onDragStart={(e) => e.preventDefault()} />

            <div className="box">
                {this.state.error !== undefined ?
                    <div className="error-box">
                        <p>{this.state.error}</p>
                    </div> : null}

                    <div className="form-section">
                        <h4>New Password</h4>
                        <input id="inputNewPassword"
                            type="password"
                            placeholder="•••••••••••"
                            onChange={this.onInputChange.bind(this)} />
                    </div>

                    <div className="form-section">
                        <h4>Confirm New Password</h4>
                        <input id="inputNewPasswordConfirm"
                            type="password"
                            placeholder="•••••••••••"
                            onChange={this.onInputChange.bind(this)}
                            onKeyPress={this.handleKeyInput.bind(this)} />
                    </div>

                <button className="button" style={{
                    width: '100%',
                    opacity: this.state.requesting ? 0.5 : 1.0
                }} onClick={this.onButtonClicked.bind(this)}>{this.state.requesting ? "Resetting Password..." : "Reset Password"}</button>
            </div>
        </div>
    }

    onInputChange(e) {
        const currentState = this.state

        if (e.target.id === "inputNewPassword") {
            currentState.newPassword = e.target.value
        } else {
            currentState.confirmNewPassword = e.target.value
        }

        this.setState(currentState)
    }

    handleKeyInput(e) {
        if (e.key === "Enter") {
            this.onButtonClicked();
        }
    }

    onButtonClicked(e) {
        if (e !== undefined) {
            e.preventDefault();
        }

        if (this.state.requesting) {
            return;
        }

        if (this.state.newPassword !== this.state.confirmNewPassword) {
            const currentState = this.state
            currentState.error = "Your new password inputs must match."
            this.setState(currentState)
            return
        }

        const currentState = this.state;
        currentState.requesting = true;
        this.setState(currentState);

        Login.confirmPasswordReset(this.state.resetKey, this.state.newPassword).then((result) => {
            if (result.error !== undefined) {
                const currentState = this.state;
                currentState.error = result.error;
                currentState.requesting = false;
                this.setState(currentState);
                return
            }

            alert(result.message)
            window.location = "/";
        }).catch((error) => {
            const currentState = this.state;
            currentState.error = error;
            currentState.requesting = false;
            this.setState(currentState);
        })
    }

}
