import React from 'react'

const Organizations = require("../../MaxJS/MaxJS.js").Organizations;
const Goals = require("../../MaxJS/MaxJS.js").Goals;

export default class GoalDetailModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loggedInUser: props.loggedInUser,
            topLevelGoals: [],
            organization: props.organization,
            goal: props.goal
        }

        Organizations.getTopLevelGoals().then((goals) => {
            let currentState = this.state
            currentState.topLevelGoals = goals;
            this.setState(currentState)
        }).catch((error) => {
            console.error(error);
        })
    }

    render() {
        return <div id={"editGoalModal-" + this.state.goal.id} className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{backgroundColor: 'rgb(255, 255, 255)', borderRadius: 20}}>
                        <div className="modal-header">
                            <h4 className="modal-title">{this.state.goal.title}</h4>
                        </div>

                        <div className="modal-body">
                            {this.state.error === undefined ? null : <p className="error">{this.state.error}</p>}

                            <form>
                                <div className="form-group">
                                    <label>Goal Name</label>
                                    <input type="text" className="form-control" id={"gdm-title-" + this.state.goal.id} value={this.state.title || this.state.goal.title} placeholder="Goal Name" onChange={(e) => {
                                        const currentState = this.state
                                        currentState.title = e.target.value
                                        this.setState(currentState)
                                    }} />
                                </div>

                                <div className="form-group">
                                    <label>Parent Goal</label>
                                    {this.renderGoalSelect()}
                                </div>

                                <div className="form-group">
                                    <label>Notes</label>
                                    <textarea className="form-control" id={"gdm-title-" + this.state.goal.id} placeholder="Notes..." defaultValue={this.state.goal.notes} style={{minHeight: 200}} onChange={(e) => {
                                        const currentState = this.state
                                        currentState.notes = e.target.value
                                        this.setState(currentState)
                                    }} />
                                </div>
                            </form>
                        </div>

                        <div className="button-holder" style={{gridTemplateColumns: "auto auto auto"}}>
                            <button type="button" className="btn" onClick={(e) => window.location.reload()} style={{backgroundColor: '#5A5A5A'}}>Cancel</button>
                            <button type="button" className="btn" style={{backgroundColor: '#c22004'}} onClick={(e) => {
                                e.preventDefault();

                                Goals.deleteGoal(this.state.goal.id).then(() => {
                                    window.location.reload();
                                }).catch((error) => {
                                    const currentState = this.state
                                    currentState.error = error
                                    this.setState(currentState)
                                });
                            }}>Delete Goal</button>
                            <button type="button" className="btn" style={{backgroundColor: '#167efb'}} onClick={this.onSubmitClicked.bind(this)}>Save Goal</button>
                        </div>
                    </div>
                </div>
            </div>
    }

    renderGoalSelect() {
        let options = [<option value={""} key={-1}>No Parent</option>];
        for (const goal of this.state.topLevelGoals) {
            options.push(<option value={goal.id} key={goal.id}>{goal.title}</option>)
        }

        return <select id="ngm-parentgoal" className="form-control" value={this.state.parent || this.state.goal.parent || ""} onChange={(e) => {
            const currentState = this.state
            currentState.parent = e.target.value
            this.setState(currentState)
        }}>
            {options}
        </select>
    }

    onSubmitClicked(e) {
        e.preventDefault()

        Goals.editGoal(this.state.goal.id, {title: this.state.title, parent: this.state.parent, notes: this.state.notes}).then((goal) => {
            window.location.reload()
        }).catch((error) => {
            const currentState = this.state
            currentState.error = error
            this.setState(currentState)
        })

        return
    }

}
