let cookies = undefined;
let sendRequest = undefined;
let user = undefined;

const configure = (config) => {
    sendRequest = config.sendRequest;
    cookies = config.cookies;
}

const login = (email, password) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/users/login", {"email": email, "password": password},
        (results) => {
            handleLoginResults(results)

            resolve(results)
        }, (xhr, status, error) => {
            if (xhr.responseJSON === undefined) {
                console.log(xhr);
                return reject("Failed to load error. Is the server down?");
            }

            reject(xhr.responseJSON.error);
        })
    });
};

const sendPasswordResetRequest = (email) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/users/passwordReset", {"email": email},
        (results) => {
            handleLoginResults(results)

            resolve(results)
        }, (xhr, status, error) => {
            reject(xhr.responseJSON.error);
        })
    });
};

const confirmPasswordReset = (key, password) => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/users/confirmPasswordReset", {"resetKey": key, "newPassword": password},
        (results) => {
            handleLoginResults(results)

            resolve(results)
        }, (xhr, status, error) => {
            reject(xhr.responseJSON.error);
        })
    });
}

const refreshSession = () => {
    loginWithKey()
}

const loginWithKey = () => {
    return new Promise((resolve, reject) => {
        sendRequest("POST", "/v1/users/login", {},
        (results) => {
            handleLoginResults(results)
            resolve(results);
        }, (xhr, status, error) => {
            // Not logged in!!
            cookies.set("sessionKey", undefined, { path: '/' });
            localStorage.setItem("user", undefined);
            resolve(error)
        })
    })
}

function handleLoginResults(results) {
    if (results.sessionKey === undefined) {
        results.sessionKey = cookies.get("sessionKey");
    }

    cookies.set("sessionKey", results.sessionKey, { path: '/' });
    localStorage.setItem("user", JSON.stringify(results));
}

const getUser = () => {
    if (user === undefined && localStorage.getItem("user") !== undefined) {
        user = JSON.parse(localStorage.getItem("user"));
    }

    return user;
}

const logout = () => {
    cookies.set("sessionKey", undefined, { path: '/' });
    localStorage.setItem("user", undefined);
    window.location = "/"
    window.location.reload();
}

export { configure, refreshSession, login, getUser, loginWithKey, logout, confirmPasswordReset, sendPasswordResetRequest }
