import React from 'react';

import GlobalAdminCheckmark from '../GlobalAdminCheckmark.js';
import { Link } from 'react-router-dom';

import './UserRow.css';

export default function UserRow(props: {
  user: any,
  linkLocation?: string,
  isAdminContext?: boolean,
  onClick?: (() => void)
}) {
  if (props.onClick !== undefined) {
    return <div className="userRow" onClick={(e) => {
      e.preventDefault();
      if (props.onClick !== undefined) props.onClick();
    }}>
      <a href={props.user.id}><InternalRow user={props.user} /></a>
    </div>
  }

  let linkLocation;
  if (props.linkLocation !== undefined) {
    linkLocation = props.linkLocation;
  } else {
    linkLocation = props.isAdminContext !== true ? "/user/" + props.user.id : "/admin/user/" + props.user.id;
  }

  return <div className="userRow">
    <Link to={linkLocation}>
      <InternalRow user={props.user} />
    </Link>
  </div>
}

function InternalRow(props: { user: any }) {
  let directReportStatus = "";
  if (props.user.directReports !== undefined) {
    const directReportTotal = props.user.directReports.length;
    directReportStatus += "• " + (directReportTotal === 0 ? "No Direct Reports" : directReportTotal + " Direct Report" + (directReportTotal !== 1 ? "s" : ""));
  }

  return <div className="media">
    <img className="mr-3" src={props.user.pictureUrl || "../../defaultProfile.png"} alt={props.user.name} style={{ width: 60, height: 60, borderRadius: 30, objectFit: 'cover', backgroundColor: '#D8D8D8' }} />
    <div className="media-body">
      <h3 className="mt-0" style={{ fontWeight: 650, marginBottom: 0, paddingTop: 7 }}>{props.user.name} <GlobalAdminCheckmark user={props.user} /> </h3>
      <h4>{props.user.role || "No role assigned"} {directReportStatus}</h4>
    </div>
  </div>
}