import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import UserController from "../Phoenix/Controller/UserController";
import PhoenixContext from "../Phoenix/PhoenixContext";

export default function PersonalInfoModal(props) {
  const [error, setError] = useState(null);
  const [updatedInfo, setUpdatedInfo] = useState(props.user.personalInfo);

  const phoenix = useContext(PhoenixContext);
  const userController = new UserController(phoenix);

  const updatePersonalInfo = async (newInfo) => {
    props.hideCallback();
    const originalInfo = props.user.personalInfo;
    props.user.personalInfo = newInfo;

    const response = await userController.editUser({
      id: props.user.id,
      personalInfo: newInfo
    });

    if (response.error !== undefined) {
      setError(response.error);
      props.user.personalInfo = originalInfo;
    }
  };

  return <Modal show={props.show}>
    <Modal.Header>
      <h4 className="modal-title">{props.user.name} - Personal Info</h4>
    </Modal.Header>

    <Modal.Body>
      {error === null ? null : <p className="error">{error}</p>}

      <div className="form-group">
        <textarea className="form-control" placeholder="..." defaultValue={props.user.personalInfo} style={{ minHeight: 200 }} onChange={(e) => {
          setUpdatedInfo(e.target.value);
        }} />
      </div>
    </Modal.Body>

    <div className="button-holder"
      style={{ gridTemplateColumns: "auto auto" }}>
      <button type="button"
        className="btn"
        data-dismiss="modal"
        onClick={(e) => {
          props.hideCallback();
        }}
        style={{ backgroundColor: '#5A5A5A' }}>
        Cancel
      </button>
      <button type="button"
        className="btn"
        style={{ backgroundColor: '#167efb' }}
        onClick={() => {
          updatePersonalInfo(updatedInfo);
        }}>
        Save
      </button>
    </div>
  </Modal>
}