import React from 'react';
import { Link } from 'react-router-dom';

const Users = require("../../MaxJS/MaxJS.js").Users;
const Goals = require("../../MaxJS/MaxJS.js").Goals;

export default class SetupWizard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [{
                name: "",
                email: "",
                role: ""
            }],
            goals: [{
                name: ""
            }],
            creating: false,
            finished: false
        }
    }

    render() {
        if (this.state.finished) {
            return <div className="container">
                <h3 style={{fontWeight: 600}}>Welcome to Business Goals!</h3>
                <p>This setup wizard will help you get a jump start with creating users and goals.</p>
                <br/>
                <p>Users and goals created!</p>
                <br/>
                <Link to="/admin">
                <button className="button"
                    style={{paddingLeft: 15, paddingRight: 15}}>
                    Open Admin Page
                  </button>
                </Link>
            </div>
        }

        if (this.state.creating) {
            return <div className="container">
                <h3 style={{fontWeight: 600}}>Welcome to Business Goals!</h3>
                <p>This setup wizard will help you get a jump start with creating users and goals.</p>
                <br/>
                <p>Creating users and goals...</p>
                <br/>
                <button className="button"
                  style={{paddingLeft: 15, paddingRight: 15, opacity: this.state.creating ? 0.5 : 1.0}}
                  disabled={this.state.creating}>
                  Create Users & Goals
                </button>
            </div>
        }

        var userComponents = [];

        for (let x = 0; x < this.state.users.length; x++) {
            userComponents.push(
                <div className="user" key={x}>
                    <p>User #{x + 1}</p>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <input type="text" id={"user_name_" + x} className="form-control" placeholder="Name" onChange={this.formDidChange.bind(this)} />
                        </div>
                        <div className="form-group col-md-4">
                            <input type="email" id={"user_email_" + x} className="form-control" placeholder="Email" onChange={this.formDidChange.bind(this)} />
                        </div>
                        <div className="form-group col-md-4">
                            <input type="text" id={"user_role_" + x} className="form-control" placeholder="Role" onChange={this.formDidChange.bind(this)} />
                        </div>
                    </div>
                </div>
            )
        }

        var goalComponents = [];

        for (let x = 0; x < this.state.goals.length; x++) {
            goalComponents.push(
                <div className="goal" key={x}>
                    <p>Goal #{x + 1}</p>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <input type="text" id={"goal_name_" + x} className="form-control" placeholder="Goal" onChange={this.formDidChange.bind(this)} />
                        </div>
                    </div>
                </div>
            )
        }

        return <div className="container">
              <h3 style={{fontWeight: 600}}>Welcome to Business Goals!</h3>
              <p>This setup wizard will help you get a jump start with creating users and goals.</p>

              <h3 style={{paddingTop: 25, paddingBottom: 5}}>Who are your people?</h3>
              {userComponents}
              <div className="form-group col-md-3">
                  <a href="#createAnotherUser" style={{style: 'rgba(0, 122, 255, 1.0)'}} onClick={(e) => {
                      e.preventDefault();

                      const currentState = this.state;
                      currentState.users.push({
                          name: "",
                          email: "",
                          role: ""
                      });
                      this.setState(currentState);
                  }}>Create Another User</a>
              </div>

              <h3 style={{paddingTop: 25, paddingBottom: 5}}>What are your company's Goals?</h3>
              {goalComponents}
              <div className="form-group col-md-3">
                  <a href="#createAnotherGoal" style={{style: 'rgba(0, 122, 255, 1.0)'}} onClick={(e) => {
                      e.preventDefault();

                      const currentState = this.state;
                      currentState.goals.push({
                          name: "",
                          notes: ""
                      });
                      this.setState(currentState);
                  }}>Add Goal</a>
              </div>

              <button className="button"
                style={{paddingLeft: 15, paddingRight: 15, opacity: this.state.creating ? 0.5 : 1.0}}
                onClick={this.createUsersAndGoals.bind(this)}
                disabled={this.state.creating}>
                Create Users & Goals
              </button>
        </div>
    }

    formDidChange(e) {
        const id = e.target.id;
        const elements = id.split("_");

        const valueType = elements[1];
        const objectId = Number(elements[2]);

        const currentState = this.state;

        if (elements[0] === "user") {
            if (valueType === "name") {
                currentState.users[objectId].name = e.target.value;
            } else if (valueType === "email") {
                currentState.users[objectId].email = e.target.value;
            } else if (valueType === "role") {
                currentState.users[objectId].role = e.target.value;
            }
        } else if (elements[0] === "goal") {
            if (valueType === "name") {
                currentState.goals[objectId].name = e.target.value;
            }
        }

        this.setState(currentState);
    }

    createUsersAndGoals() {
        if (this.state.creating) {
            return;
        }

        const currentState = this.state;
        currentState.creating = true;
        this.setState(currentState);

        const tasksToComplete = currentState.users.length + currentState.goals.length;
        let completedTasks = 0;

        function checkCompletion(instance) {
            if (completedTasks === tasksToComplete) {
                const currentState = instance.state;
                currentState.finished = true;
                instance.setState(currentState);
            }
        }

        currentState.users.forEacH((user) => {
          if (user.name.length === 0 || !user.name.trim()) {
              console.error("Empty user name found, skipping")
              completedTasks += 1;
              checkCompletion(this);
              return;
          }

          if (user.email.length === 0 || !user.email.trim()) {
              console.error("Empty user email found, skipping")
              completedTasks += 1;
              checkCompletion(this);
              return;
          }

          if (user.role.length === 0 || !user.role.trim()) {
              console.error("Empty user role found, skipping")
              completedTasks += 1;
              checkCompletion(this);
              return;
          }

          const instance = this;

          Users.createUser({
              name: user.name,
              email: user.email,
              role: user.role,
              reportsTo: this.props.loggedInUser.id
          }, undefined).then(() => {
              completedTasks += 1;
              checkCompletion(instance);
          }).catch((error) => {
              console.error(error);

              completedTasks += 1;
              checkCompletion(instance);
          })
        });

        currentState.goals.forEach((goal) => {
          if (goal.name.length === 0 || !goal.name.trim()) {
              console.error("Empty goal name found, skipping")
              completedTasks += 1;
              checkCompletion(this);
              return;
          }

          const instance = this;

          Goals.createGoal(goal.name, undefined, this.props.loggedInUser.topLevelOrg).then(() => {
              completedTasks += 1;
              checkCompletion(instance);
          }).catch((error) => {
              console.error(error);

              completedTasks += 1;
              checkCompletion(instance);
          })
        });
    }

}
