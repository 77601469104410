import React, { useState, useContext } from 'react';

import PhoenixContext from '../Phoenix/PhoenixContext';
import AuthenticationController from '../Phoenix/Controller/AuthenticationController';

import './LoginComponent.css'

export default function LoginComponent(props) {
  const [screen, setScreen] = useState("login");
  const [requestingLogin, setRequestingLogin] = useState(false);
  const [requestingPasswordReset, setRequestingPasswordReset] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const phoenix = useContext(PhoenixContext);
  const authenticationController = new AuthenticationController(phoenix);

  const handleKeyInput = (e) => {
    if (e.key === "Enter") {
      onLoginClicked();
    }
  }

  const onLoginClicked = async (e) => {
    if (e !== undefined) {
      e.preventDefault();
      e.persist();
    }

    if (requestingLogin) {
      return;
    }

    setRequestingLogin(true);

    const response = await authenticationController.login(email, password);

    if (response.body !== undefined && response.body.user !== undefined) {
      props.setUser(response.body.user);
      return;
    }

    console.error("Login Response", response);

    setRequestingLogin(false);
    setError(response.error !== undefined ? response.error : "Something went wrong. Please try again later.")
  }

  const onPasswordResetClicked = async (e) => {
    e.preventDefault();
    e.persist();

    if (screen !== "passwordReset") return;

    if (requestingPasswordReset) {
      return;
    }

    setRequestingPasswordReset(true);

    const response = await authenticationController.requestPasswordReset(email);
    setRequestingPasswordReset(false);

    if (response.error !== undefined) {
      setError(response.error);
      return;
    }

    setMessage(response.message);
  }

  if (screen === "passwordReset") {
    return <PasswordReset setScreen={setScreen}
      onPasswordResetClicked={onPasswordResetClicked}
      email={email}
      password={password}
      setEmail={setEmail}
      requestingPasswordReset={requestingPasswordReset}
      error={error}
      setError={setError}
      message={message} />
  }

  return <div style={{ marginTop: 40 }}>
    <img src="../MaxDark.png" className="logo" alt="Goals" onDragStart={(e) => e.preventDefault()} />

    <div className="box">
      {error !== undefined && error !== null ?
        <div className="error-box">
          <p>{error}</p>
        </div> : null}

      {message !== undefined && message !== null ?
        <div className="message-box">
          <p>{message}</p>
        </div> : null}

      <div className="form-section">
        <h4>Email</h4>
        <input id="inputEmail"
          type="email"
          placeholder="john.appleseed@businessgoals.org"
          onChange={(e) => {
            setEmail(e.target.value);
            setError(null);
          }} />
      </div>

      <div className="form-section">
        <h4>Password</h4>
        <input id="inputPassword"
          type="password"
          placeholder="•••••••••••"
          onChange={(e) => {
            setPassword(e.target.value);
            setError(null);
          }}
          onKeyPress={handleKeyInput} />
      </div>

      <button className="button" style={{
        width: '100%',
        opacity: requestingLogin ? 0.5 : 1.0
      }} onClick={onLoginClicked}>{requestingLogin ? "Logging in..." : "Login"}</button>

      <p className="foot-button" style={{
        color: '#007AFF',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: 8,
        marginTop: 15
      }}>
        {requestingLogin ? "Forgot Password?" : <a style={{ cursor: 'pointer' }} href="#resetPassword" onClick={(e) => {
          e.preventDefault();

          setError(null);
          setScreen("passwordReset");
        }}>Forgot Password?</a>}
      </p>
    </div>
  </div>;
}

function PasswordReset(props) {
  return <div style={{
    marginTop: 40
  }}>
    <img src="../MaxDark.png" className="logo" alt="Goals" onDragStart={(e) => e.preventDefault()} />

    <div className="box">
      {props.error !== undefined && props.error !== null ?
        <div className="error-box">
          <p>{props.error}</p>
        </div> : null}

      {props.message !== undefined && props.message !== null ?
        <div className="message-box">
          <p>{props.message}</p>
        </div> : null}

      <div className="form-section">
        <h4>Email</h4>
        <input id="inputEmail"
          type="email"
          placeholder="john.appleseed@businessgoals.org"
          defaultValue={props.email}
          onChange={(e) => {
            e.preventDefault();

            props.setEmail(e.target.value);
            props.setError(null);
          }} />
      </div>

      <button className="button" style={{
        width: '100%',
        opacity: props.requestingPasswordReset ? 0.5 : 1.0
      }} onClick={props.onPasswordResetClicked}>{props.requestingPasswordReset ? "Requesting Password Reset..." : "Reset Password"}</button>

      <p className="foot-button" style={{
        color: '#007AFF',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: 8,
        marginTop: 15
      }}>
        {props.requestingPasswordReset ? "Login Instead" : <a style={{ cursor: 'pointer' }} href="#loginInstead" onClick={(e) => {
          e.preventDefault();

          props.setError(null);
          props.setScreen("login");
        }}>Login Instead</a>}
      </p>
    </div>
  </div>
}