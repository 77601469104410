import React from 'react'

import './DepartmentRow.scss';
import chevron from '../../../Back128.png'

import { Link } from 'react-router-dom';

export default function DepartmentRow(props: {department: any, linkLocation?: any}) {
  return <Link to={props.linkLocation !== undefined ? props.linkLocation : `/team/${props.department.id}`} className="departmentRowLink">
      <div className="departmentRow">
      <h3 className="title">{props.department.name} <img src={chevron} alt="Chevron" /></h3>
    </div>
  </Link>
}