import React from 'react';

import GoalDetailModal from './TeamPage/GoalDetailModal.js'
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default class GoalView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            goal: props.goal,
            index: props.index,
            loggedInUser: props.loggedInUser
        }
    }

    render() {
        const goal = this.state.goal;
        const index = this.state.index;

        return <div>
                <GoalDetailModal goal={this.state.goal} organization={this.state.goal.organization} loggedInUser={this.state.loggedInUser} />

                <div className="card-shadow" style={{
                    marginTop: index !== 0 ? 10 : 0,
                    borderRadius: 10,
                    padding: 12,
                    textDecoration: 'none'
                }}>
                    {/* <CircularProgressbar
                      value={5}
                      strokeWidth={50}
                      text={5 + "%"}
                      styles={buildStyles({
                        strokeLinecap: 'butt',
                        trailColor: 'transparent',
                        height: 40,
                        width: 40,
                        textSize: 12
                      })} /> */}

                    {goal.parent !== null && goal.parent !== -1 ? <div style={{display: 'inline-block'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <p style={{
                                    backgroundColor: '',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                    fontWeight: '500',
                                    marginBottom: 0,
                                    display: 'inline',
                                }}>{goal.parentTitle}</p>
                            </div>
                        </div> : null}

                    <a href="#goal-details"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-target={"#editGoalModal-" + this.state.goal.id}><p style={{
                            marginBottom: 0,
                            marginTop: -2,
                            color: "black",
                            fontWeight: "600",
                            lineHeight: 1.8
                        }}>{goal.title}</p> </a>
                </div>
            </div>
    }

}
