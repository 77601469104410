import { Endpoint } from '../Endpoint';
import { HTTPMethod, HTTPRequest, HTTPResponse, Phoenix } from '../Phoenix';
import User from './Model/User'
import UserReport from './Model/UserReport'

export default class UserController {
  phoenix: Phoenix

  constructor($phoenix: Phoenix) {
    this.phoenix = $phoenix;
  }

  getUserTree(): Promise<HTTPResponse<any>> {
    return this.phoenix.perform(new HTTPRequest<any>(HTTPMethod.get, Endpoint.users, `/usertree`));
  }

  getUser(id: number): Promise<HTTPResponse<User>> {
    return this.phoenix.perform(new HTTPRequest<User>(HTTPMethod.get, Endpoint.users, `/${id}`));
  }

  getUsers(): Promise<HTTPResponse<User[]>> {
    return this.phoenix.perform(new HTTPRequest<User[]>(HTTPMethod.get, Endpoint.users, `/`));
  }

  getReportsForCurrentWeek(userId: number): Promise<HTTPResponse<UserReport[]>> {
    return this.phoenix.perform(new HTTPRequest<UserReport[]>(HTTPMethod.get, Endpoint.userReports, `/${userId}/currentWeek`));
  }

  createUser(user: {
    name: string,
    email?: string
    role?: string,
    globalAdmin?: boolean,
    reportsTo?: number,
    team?: number
  }): Promise<HTTPResponse<any>> {
    return this.phoenix.perform(new HTTPRequest<HTTPResponse<any>>(HTTPMethod.post, Endpoint.users, `/`, user))
  }

  editUser(user: {
    id: number,
    name: string,
    email: string,
    role: string,
    reportsTo: number,
    topLevelOrg: number,
    team: number,
    isGlobalAdmin: boolean,
    personalInfo: string
  }): Promise<HTTPResponse<User>> {
    return this.phoenix.perform(
      new HTTPRequest<User>(HTTPMethod.patch, Endpoint.users, `/${user.id}`, user)
    )
  }

  deleteUser(user: any): Promise<HTTPResponse<any>> {
    return this.deleteUserWithId(user.id)
  }

  deleteUserWithId(userId: number): Promise<HTTPResponse<any>> {
    return this.phoenix.perform(new HTTPRequest<any>(HTTPMethod.delete, Endpoint.users, `/${userId}`))
  }

  uploadProfilePicture(userId: number, formData: FormData): Promise<HTTPResponse<any>> {
    return this.phoenix.performImageRequest(new HTTPRequest<any>(HTTPMethod.post, Endpoint.users, `/${userId}/profile-picture`), formData)
  }

  convertUserTreeToOptionsList(topUsers: any[]): any[] {
    let options: any[] = [];

    const add = (user: any, level: number) => {
      let dashesString = "";

      for (let i = 0; i < level; i++) {
        dashesString += "-"
      }

      options.push({ id: user.id, name: dashesString + user.name })

      if (user.directReports !== undefined && user.directReports.length !== 0) {
        for (const child of user.directReports) {
          add(child, level + 1)
        }
      }
    }

    for (const user of topUsers) {
      add(user, 0);
    }

    return options;
  }

}