import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';

import OrgChart from '../../OrgChart/OrgChart.js'
import OrganizationController from '../../Phoenix/Controller/OrganizationController';
import PhoenixContext from '../../Phoenix/PhoenixContext';

export default function OrgTreePage(props) {
  const [orgTree, setOrgTree] = useState(null);
  const [error, setError] = useState(null);

  const history = useHistory();

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);

  useEffect(() => {
    async function loadTree() {
      const response = await organizationController.getOrgTree();
      if (response.body !== undefined) {
        setOrgTree(response.body.orgTree);
      } else {
        setError(response.error);
      }
    }

    loadTree();
    // eslint-disable-next-line
  }, []);

  if (orgTree === null || error !== null) {
    return <div className="container-fluid" style={{ maxWidth: '90%' }}>
      <h2 style={{ fontWeight: 700, marginBottom: 0 }}>{error !== null ? error : "Loading..."}</h2>
      <p style={{ fontSize: 18, fontWeight: 600 }}><a href="/" onClick={(e) => {
        e.preventDefault();

        history.push("/");
      }}>Back to My Tasks</a></p>
    </div>
  }

  return <div style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
    <h1 style={{ fontWeight: 'bold' }}>{orgTree.name}</h1>
    <p style={{ fontSize: 18, fontWeight: 600 }}><a href="/" onClick={(e) => {
      e.preventDefault();

      history.push("/");
    }}>Back to My Tasks</a></p>

    <br />

    <OrgChart tree={orgTree} fullWidth={true} canClick={(node) => {
      return !node.topLevel
    }} onClick={(node) => {
      if (!node.topLevel) {
        history.push(`/team/${node.id}`);
      }
    }} />
  </div>
}
