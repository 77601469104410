import React from 'react';

export default function PrivacyPolicy() {
  return <div style={{ margin: 50 }}>
    <h1>Business Goals Privacy Policy</h1>
    <h4>You must read, agree with and accept all of the terms and conditions contained in this User Agreement and the Privacy Policy before using your Buisness Goals account.</h4>
    <p>Business Goals collects e-mail addresses of people who send us e-mail. We also collect information on what pages users access and information provided to us by users via surveys and site registrations. Such information may contain personal data about you including your name, address, phone numbers, etc. Business Goals offloads storing credit card information to user authorized third parties certified Level 1 according to Payment Card Industry Data Security Standards (PCI-DSS). Certain information collected from you and about you is used within the context of providing the Service. The information we collect is not shared with or sold to others except under the certain circumstances and which your use of the Service is deemed to provide to us a valid consent to disclose the following:</p>
    <p>1. In order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Business Goals's terms of use, or as otherwise required by law.</p>
    <p>2. Business Goals does not rent, sell, or share personal information about you with other companies except to provide products or services you've requested or when we have your permission.</p>
    <p>3. We will transfer information about you if Business Goals is acquired by or merged with another company. In this event, Business Goals will notify you by email or by putting a prominent notice on the Business Goals web site before information about you is transferred and becomes subject to a different privacy policy.</p>
    <p>Business Goals may periodically update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your Business Goals service primary account holder account or by placing a prominent notice on our site. You retain the right to access, amend, correct or delete your personal information where it is inaccurate at any time. To do so, please contact support@buisnessgoals.org.</p>
    <h2>SECTION 1</h2>
    <h4>How we use your information</h4>
    <p>1. When you register for any Business Goals product we ask for information such as your name, company name, email address, billing address, credit card information.</p>
    <p>2. Business Goals uses collected information for the following general purposes: products and services provision, billing, identification and authentication, services improvement, contact, and research.</p>
    <p>3. Business Goals does not share your personal information with third parties without your authorization.</p>
    <h2>SECTION 2</h2>
    <h4>Cookies</h4>
    <p>1. A cookie is a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Every computer that accesses our website is assigned a different cookie by us.</p>
    <h2>SECTION 3</h2>
    <h4>Data Storage</h4>
    <p>1. Business Goals uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run our services. Although Business Goals owns the code, databases, and all rights to the Business Goals applications, you retain all rights to your data.</p>
    <h2>SECTION 4</h2>
    <h4>Disclosure</h4>
    <p>1. Business Goals may disclose personally identifiable information under special circumstances, such as to comply with subpoenas or when your actions violate the Terms of Service of any of our applications.</p>
    <p><b>Questions?</b> Any questions about this Privacy Policy should be addressed to support@buisnessgoals.org.</p>
  </div>
}