import React from 'react'
import DatePicker from "react-datepicker";

import { Modal } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";

const Tasks = require("../../../MaxJS/MaxJS").Tasks;

export default class EditTaskModal extends React.Component {

    constructor(props) {
        super(props)

        let recurring;

        let recurringType = "none";
        let recurringData = "";

        if (props.task !== null) {
             recurring = props.task.recurring

            if (recurring === "none" || recurring === "daily") {
                recurringType = recurring;
            } else {
                let split = recurring.split("|");

                if (split.length > 1) {
                    recurringType = split[0];
                    recurringData = split[1];
                } else {
                    recurringType = recurring
                }
            }
        }

        let recurringDataMonthlyOptions = [];

        for (let x = 1; x <= 31; x++) {
            recurringDataMonthlyOptions.push(x);
        }

        this.state = {
            task: props.task,
            idPrefix: "TASKEDITMODAL" + props.task.id,
            orgs: [],
            recurringType: recurringType,
            recurringData: recurringData,
            recurringDataWeeklyOptions: [
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday"
            ],
            recurringDataMonthlyOptions: recurringDataMonthlyOptions
        }

        // Goals.getGoalTreeForOrg(props.user.team || props.user.topLevelOrg).then((result) => {
        //     const currentState = this.state
        //
        //     let orgs = [];
        //
        //     result.orgMetadata.forEach((org) => {
        //         org.goals = [];
        //
        //         result.goals.forEach((goal) => {
        //             if (org.goalIds.includes(goal.id)) {
        //                 org.goals.push(goal)
        //             }
        //         })
        //
        //         if (org.goals.length !== 0) {
        //             orgs.push(org)
        //         }
        //     })
        //
        //     currentState.orgs = orgs
        //     this.setState(currentState)
        // })
    }

    render() {
        return <Modal show={this.props.show} size="lg">
            <Modal.Header>
                <h4 className="modal-title">{this.state.task.title}</h4>
            </Modal.Header>

            <Modal.Body>
                {this.state.error === undefined ? null : <p className="error">{this.state.error}</p>}

                <form>
                    <div className="form-group">
                        <label htmlFor={this.state.idPrefix + "-name"}>Task Name</label>
                        <input type="text"
                            className="form-control"
                            id={this.state.idPrefix + "-name"}
                            placeholder="Name"
                            defaultValue={this.state.task.title || this.state.title}
                            onChange={this.onInputChange.bind(this)} />
                    </div>

                    <div className="form-group">
                        <label htmlFor={this.state.idPrefix + "-duedate"}>Due Date</label>
                        <br/>
                        <DatePicker
                            id={this.state.idPrefix + "-duedate"}
                            type="date"
                            className="form-control DatePicker"
                            selected={this.state.dueDate || new Date(this.state.task.dueDate)}
                            onChange={(newDueDate) => {
                                let currentState = this.state
                                currentState.dueDate = newDueDate;
                                this.setState(currentState)
                            }} />
                    </div>

                    <div className="form-group">
                        <label htmlFor={this.state.idPrefix + "-weight"}>Weight - {this.state.weight || this.state.task.weight}%</label>
                        <input type="range"
                            className="form-control"
                            id={this.state.idPrefix + "-weight"}
                            placeholder="Weight"
                            min="0"
                            max="100"
                            value={this.state.weight || this.state.task.weight}
                            onChange={this.onInputChange.bind(this)} />
                    </div>

                    <div className="form-group">
                        <label>Goal</label>
                        <select id={this.state.idPrefix + "-goal"}
                            className="form-control"
                            defaultValue={this.state.goal || this.state.task.assosciatedGoal}
                            onChange={this.onInputChange.bind(this)}>
                            <option value="-1">No Goal</option>
                            {this.state.orgs.map((org) => {
                                let options = [<option key={"org-" + org.id} disabled>{org.name}</option>]

                                org.goals.forEach((goal) => {
                                    options.push(
                                        <option value={goal.id} key={"goal-" + goal.id}>{goal.title}</option>
                                    )
                                })

                                return options
                            })}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Recurring</label>
                        <select id={this.state.idPrefix + "-recurring"}
                            className="form-control"
                            defaultValue={this.state.recurringType || "none"}
                            onChange={this.onInputChange.bind(this)}>
                            <option value="none">Not Recurring</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>

                    {this.state.recurringType === "monthly" || this.state.recurringType === "weekly" ?
                        <div className="form-group">
                            <label>{this.state.recurringType === "monthly" ? "Day of Month" : "Day of Week"}</label>
                            <select id={this.state.idPrefix + "-recurringData"}
                                className="form-control"
                                defaultValue={this.state.recurringData || (this.state.recurringType === "monthly" ? "1" : "sunday")}
                                onChange={this.onInputChange.bind(this)}>
                                {(this.state.recurringType === "monthly" ?
                                    this.state.recurringDataMonthlyOptions :
                                    this.state.recurringDataWeeklyOptions
                                ).map((item, index) => {
                                    return <option value={item} key={item}>
                                        {this.state.recurringType === "monthly" ?
                                            item :
                                            (item.charAt(0).toUpperCase() + item.slice(1))
                                        }
                                    </option>
                                })}
                            </select>
                        </div>
                        : null}

                    <div className="form-group">
                        <label>Type</label>
                        <select id={this.state.idPrefix + "-type"}
                            className="form-control"
                            defaultValue={this.state.aboveLine || this.state.task.aboveLine}
                            onChange={this.onInputChange.bind(this)}>
                            <option value="true">Above Line</option>
                            <option value="false">Below Line</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Notes</label>
                        <textarea id={this.state.idPrefix + "-notes"}
                            className="form-control"
                            defaultValue={this.state.notes || this.state.task.notes}
                            onChange={this.onInputChange.bind(this)}
                            style={{minHeight: 120}} />
                    </div>
                </form>
            </Modal.Body>

            <div style={{marginLeft: 15, marginBottom: 15, overflowX: "scroll", whiteSpace: "nowrap"}}>
                <h6 style={{marginBottom: 5}}>Attachments</h6>
                {this.state.task.attachments.map((attachment) => {
                    return <div key={attachment.id} style={{display: 'inline-block'}}>
                        <img alt="Attachment"
                            src={attachment.imageUrl}
                            style={{
                                maxWidth: 300,
                                maxHeight: 300,
                                marginTop: 5,
                                marginBottom: 5,
                                marginRight: 10,
                                objectFit: "contain"
                            }}/>
                            <br/>
                            <p style={{width: '100%', textAlign: 'center'}}>
                                <a href="#delete-attachment" onClick={(e) => {
                                    e.preventDefault();

                                    Tasks.deleteAttachment(this.state.task.id, attachment.id).then((result) => {
                                        if (result.error !== undefined) {
                                            window.alert(result.error)
                                            return
                                        }

                                        const currentState = this.state
                                        currentState.task = result
                                        this.setState(currentState)
                                    }).catch((error) => {
                                        window.alert(error)
                                    })
                                }}>Delete</a>
                            </p>
                    </div>
                })}

                {this.state.task.attachments.length === 0 ? null : <br></br>}

                <a href="#add-attachment" id={"attachmentImageButton-" + this.state.task.id} onClick={(e) => {
                    if (e.target.disabled) {
                        return;
                    }

                    e.preventDefault();
                    this.openTaskAttachmentImageSelector()
                }}>Add Attachment</a>

                <form id={"attachmentForm-" + this.state.task.id}>
                    <input type="file"
                        name="file"
                        id={"attachmentInput-" + this.state.task.id}
                        ref={"attachmentUploader-" + this.state.task.id}
                        style={{display: "none"}}
                        accept="image/*"
                        onChange={this.imageSelected.bind(this)} />
                </form>
            </div>

            <div className="button-holder" style={{gridTemplateColumns: "auto auto auto"}}>
                <button type="button" className="btn" onClick={(e) => window.location.reload()} style={{backgroundColor: '#5A5A5A'}}>Cancel</button>
                <button type="button" className="btn" onClick={(e) => {
                    e.preventDefault();

                    Tasks.deleteTask(this.state.task.id).then(() => {
                        window.location.reload();
                    })
                }} style={{backgroundColor: '#c22004'}}>Delete Task</button>
                <button type="button" className="btn" style={{backgroundColor: '#167efb'}} onClick={this.onSubmitClicked.bind(this)}>Save Task</button>
            </div>
            </Modal>
    }

    openTaskAttachmentImageSelector(e) {
        this.refs["attachmentUploader-" + this.state.task.id].click();
    }

    imageSelected(e) {
        const taskId = e.target.id.split("-")[1]

        document.getElementById("attachmentImageButton-" + taskId).innerHTML = "Uploading..."
        document.getElementById("attachmentImageButton-" + taskId).disabled = true;

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file);

        Tasks.addAttachment(this.state.task.id, formData).then((result) => {
            if (result.error !== undefined) {
                window.alert(result.error)
                return
            }

            document.getElementById("attachmentImageButton-" + taskId).innerHTML = "Add Attachment";
            document.getElementById("attachmentImageButton-" + taskId).disabled = false;

            const currentState = this.state
            currentState.task = result
            this.setState(currentState)
        });
    }

    onInputChange(e) {
        const id = e.target.id.split("-")[1];
        const value = e.target.value;

        let currentState = this.state

        if (id === "name") {
            currentState.title = value;
        } else if (id === "weight") {
            currentState.weight = value;
        } else if (id === "goal") {
            currentState.goal = value;
            console.log(value)
        } else if (id === "recurring") {
            currentState.recurringType = value;

            if (value === "weekly") {
                currentState.recurringData = "sunday";
            } else if (value === "monthly") {
                currentState.recurringData = "1";
            }
        } else if (id === "recurringData") {
            currentState.recurringData = value;
        } else if (id === "type") {
            currentState.aboveLine = value === "true";
        } else if (id === "notes") {
            currentState.notes = value;
        }

        this.setState(currentState)
    }

    onSubmitClicked(e) {
        if (this.state.name === "") {
            let currentState = this.state
            currentState.error = "Your task must have a name.";
            this.setState(currentState);
            return;
        }

        Tasks.editTask(this.state.task.id, {
            title: this.state.title,
            weight: this.state.weight,
            goal: this.state.goal,
            recurring: this.state.recurringType + (this.state.recurringData.length !== 0 ? "|" + this.state.recurringData : ""),
            aboveLine: this.state.aboveLine,
            notes: this.state.notes,
            dueDate: (this.state.dueDate || new Date(this.state.task.dueDate)).toISOString().split(".")[0],
            completed: this.state.task.completed
        }).then(() => {
            window.location.reload();
        }).catch((error) => {
            let currentState = this.state
            currentState.error = error;
            this.setState(currentState);
        })
    }

}
