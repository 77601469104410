import { Endpoint } from './Endpoint';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { v4 as uuid } from 'uuid';

const productionUrl = "https://phoenix-server.businessgoals.org";
const developmentUrl = "http://localhost:3021";

export class Phoenix {

  production: boolean
  cookies: Cookies
  apiKey: string

  constructor($production: boolean) {
    this.production = $production;
    this.cookies = new Cookies();

    if (this.production) {
      this.apiKey = "cce14c52-25f9-4118-80b6-11f018d91484";
    } else {
      this.apiKey = "1234";
    }
  }

  getHostInfo() {
    return `Phoenix URL ${this.production ? productionUrl : developmentUrl}`
  }

  performImageRequest<T>(request: HTTPRequest<T>, formData: FormData): Promise<HTTPResponse<T>> {
    const baseUrl = this.production ? productionUrl : developmentUrl;
    const url = baseUrl + request.endpoint + request.path;

    return new Promise((resolve) => {
      $.ajax({
        type: request.method,
        url: url,
        data: formData,
        beforeSend: (request) => {
          request.setRequestHeader("APIKey", this.apiKey);
          const sessionKey = this.getSessionKey();
          if (sessionKey !== undefined) {
            request.setRequestHeader("SessionKey", sessionKey);
          }
        },
        success: (results) => {
          const response = new HTTPResponse<T>(results);
          resolve(response);
        },
        processData: false,
        contentType: false,
        error: (xhr, status, error) => {
          let errorString;
          if (xhr !== undefined && xhr.hasOwnProperty('responseJSON')) {
            if (!this.production) console.log(JSON.stringify(xhr.responseJSON));
            errorString = xhr.responseJSON.error;
          } else {
            errorString = "Something went wrong."
            if (!this.production) console.log(JSON.stringify(xhr));
          }

          const errorObject = {
            error: errorString
          };

          resolve(new HTTPResponse<T>(errorObject));
        }
      })
    });
  }

  perform<T>(request: HTTPRequest<T>): Promise<HTTPResponse<T>> {
    const baseUrl = this.production ? productionUrl : developmentUrl;
    const url = baseUrl + request.endpoint + request.path;

    return new Promise((resolve) => {
      const requestId = uuid();
      const timingSignature = requestId + " " + request.endpoint + " " + request.path;
      // if (!this.production) {
        console.time(timingSignature);
      // }

      $.ajax({
        type: request.method,
        url: url,
        data: request.body,
        beforeSend: (request) => {
          request.setRequestHeader("APIKey", this.apiKey);
          const sessionKey = this.getSessionKey();
          if (sessionKey !== undefined) {
            request.setRequestHeader("SessionKey", sessionKey);
          }
        },
        success: (results) => {
          const response = new HTTPResponse<T>(results);
          resolve(response);
          if (response.sessionKey !== undefined) {
            this.setSessionKey(response.sessionKey)
          }
          // if (!this.production) 
          console.timeEnd(timingSignature);
        },
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        error: (xhr, status, error) => {
          let errorString;
          if (xhr !== undefined && xhr.hasOwnProperty('responseJSON')) {
            if (!this.production) console.log(JSON.stringify(xhr.responseJSON));
            errorString = xhr.responseJSON.error;
          } else {
            errorString = "Something went wrong."
            if (!this.production) console.log(JSON.stringify(xhr));
          }

          const errorObject = {
            error: errorString
          };

          resolve(new HTTPResponse<T>(errorObject));
          if (!this.production) console.timeEnd(timingSignature);
        }
      })
    });
  }

  getSessionKey(): string | undefined {
    return this.cookies.get("sessionKey");
  }

  private hasSessionKey(): boolean {
    return this.getSessionKey() !== undefined && this.getSessionKey() !== null && this.getSessionKey()?.length !== 0;
  }

  setSessionKey(key: string) {
    this.cookies.set(`sessionKey`, key, { path: '/', maxAge: 60 * 60 * 24 })
  }

  private logout() {
    this.cookies.remove("sessionKey", { path: '/' });
    localStorage.removeItem("user");
  }
}

export class HTTPRequest<T> {
  method: HTTPMethod
  endpoint: Endpoint
  path: string
  body: T

  constructor($method: HTTPMethod, $endpoint: Endpoint, $path: string, $body: any = {}) {
    this.method = $method;
    this.endpoint = $endpoint;
    this.path = $path;
    this.body = $body;
  }
}

export enum HTTPMethod {
  get = "GET",
  post = "POST",
  put = "PUT",
  patch = "PATCH",
  delete = "DELETE"
}

export class HTTPResponse<T> {
  message?: string
  error?: string
  body?: T
  sessionKey?: string

  constructor($rawNetworkResponse: any) {
    this.error = $rawNetworkResponse.error;
    this.message = $rawNetworkResponse.message;
    if ($rawNetworkResponse.body !== undefined) {
      this.body = $rawNetworkResponse.body
    }
    if ($rawNetworkResponse.sessionKey !== undefined) {
      this.sessionKey = $rawNetworkResponse.sessionKey;
    }
  }
}