import React from 'react'

function TermsOfService() {
  return <div style={{margin: 50}}>
    <h1>Business Goals Terms of Service</h1>
    <h4>LAST UPDATED JANUARY 21ST, 2020</h4>
    <p>In order to use the Business Goals Application, access the information contained on the Business Goals Website (the "Website") or make use of the services provided by Business Goals (the "Service" or "Services"), you (hereinafter referred to as "You") must first read this agreement (the "Agreement") and accept it. You may not avail Yourself of the Business Goals Application, Website or Services if You do not accept this Agreement and the terms therein. You accept the terms of the Agreement by clicking to accept, by agreeing to the terms of the Agreement in the user interface for any Business Goals Service, or by using the Business Goals Application or Services. In the latter case, You understand and agree that we will treat Your use of the Services as acceptance of the terms from that point onwards. BY USING THE BUSINESS GOALS APPLICATION AND THE BUSINESS GOALS WEBSITE, YOU CONSENT TO THE TERMS AND CONDITIONS OF THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE PLEASE DO NOT USE THE WEBSITE AND/OR SERVICES.</p>
    <p>By putting a check mark next to I AGREE and clicking on SUBMIT or using the Application or Services, You represent that You have read and agree to the terms and conditions of this Agreement, which also include and incorporate Business Goals's Acceptable Use Policy and Privacy Policy. These terms and conditions will remain in effect throughout Your use of the Services and continue after this Agreement expires, cancels or is terminated. These terms and conditions are legally binding should You choose to register for the Service. You may not use the Application or Services and may not accept the terms if (a) You are not of legal age to form a binding contract with us, or (b) You are a person barred from receiving the Services under the laws of the United States or other countries including the country in which You are resident or from which You use the Services.
      If You are accepting on behalf of Your employer or another entity, You represent and warrant that: (i) You have full legal authority to bind your employer, or the applicable entity, to these terms of service; (ii) You have read and understand this Agreement; and (iii) You agree, on behalf of the party that You represent, to this Agreement. If You don't have the legal authority to bind Your employer or the applicable entity, please do not put a check mark next to I AGREE and click on SUBMIT or use Business Goals Services.</p>
    <p>We reserve the right to change these Terms of Service from time to time without notice by posting them to the Business Goals website. When we do, we will also revise the "last update" date of these Terms of Service. Your continued use of the Service after such posting will constitute acceptance by You of such amendments.</p>
    <h2>1. Grant of Rights to Use Services</h2>
    <p>1.1 Subject to Your acceptance of and compliance with this Agreement and with the payment requirements for the Services, Business Goals hereby grants You a limited, non-exclusive, non-transferable, non-sublicenseable, revocable right and license during the Term of this Agreement in and under our intellectual property rights, to access and use the Services, solely in accordance with the terms and conditions of this Agreement. Unless explicitly stated otherwise, any new features provided by Business Goals that augment or enhance the current Services shall also constitute "Services" and shall be subject to these terms and conditions. You may not, nor allow any third party to, copy, distribute, sell, disclose, lend, transfer, convey, modify, decompile, disassemble or reverse engineer the Services for any purpose whatsoever. You may not allow any unauthorized third party to access the Services for any purpose whatsoever. All rights not expressly granted under this Agreement are retained by Business Goals.</p>
    <p>1.2 You may not remove, obscure, or alter any notice of any Business Goals trademark, service mark or other intellectual property or proprietary right appearing on the Website or contained within the Services.</p>
    <p>1.3 We may make available to You, for Your installation, copying and/or use in connection with the Services, from time to time, a variety of software, data and other content and printed and electronic documentation (the "Business Goals Properties"). Subject to Your acceptance of and compliance with this Agreement, ongoing compliance with its terms and conditions with respect to the subject Service, and payment if and as required for Your right to use the subject Service, Business Goals hereby grants to You a limited, non-exclusive, non-transferable, non-sublicensable, revocable license during the Term of this Agreement in and under our intellectual property rights in the Business Goals Properties, only to install, copy and use the Business Goals Properties solely in connection with and as necessary for Your use of such Services, solely in accordance with the terms and conditions of this Agreement.</p>
    <p><i>a) The Business Goals Properties may include, without limitation:
      The Business Goals Website;
      The Business Goals Application
      Specifications describing the operational and functional capabilities, use limitations, technical and engineering requirements, and testing and performance criteria relevant to the proper use of a Service and its related applications and technology.</i></p>
    <p><i>(b) Except as may be expressly authorized under this Agreement:
      You may not, and may not attempt to, modify, alter, tamper with, repair, or otherwise create derivative works of any software included in the Business Goals Properties.
      You may not, and may not attempt to, reverse engineer, disassemble, or decompile the Business Goals Properties or the Services or apply any other process or procedure to derive the source code of any software included in the Business Goals Properties.</i></p>
    <p>1.4 For purposes of this Agreement, Business Goals hereby grants to You a limited, non-exclusive, non-transferable, non-sublicenseable, revocable license during the Term of this Agreement to display the trade names, trademarks, service marks, logos, domain names of Business Goals (each, a "Business Goals Mark") for the purpose of promoting or advertising that You use the Services, solely in accordance with the terms and conditions of this Agreement. In return You hereby grant Business Goals a limited, non-exclusive, non-transferable, non-sublicenseable license during the Term of this Agreement to display Your trade names, trademarks, service marks, logos, domain names and the like for the purpose of promoting or advertising that You use the Services. In using Business Goals Marks, You may not: (i) display a Business Goals Mark in any manner that implies a relationship or affiliation with, sponsorship, or endorsement by Business Goals; (ii) use Business Goals Marks to disparage Business Goals/Goals or its products or services; or (iii) display a Business Goals Mark on a site that violates any law or regulation. Notwithstanding the above, Business Goals may determine with sole discretion whether its marks may be used in connection with Your Business. Furthermore, we may modify any Business Goals Marks provided to You at any time, and upon notice, You will use only the modified Business Goals Marks and not the old Business Goals Marks. Other than as specified in this Agreement, You may not use any Business Goals Mark unless You obtain our prior written consent. All uses of the Business Goals Trademarks and goodwill associated therewith shall inure to the benefit of Business Goals.</p>
    <p>1.5 Subject to our commercially reasonable efforts and so long as Your account remains in good standing, data generated by Your use of the Service remain available via our Website or Application for at least twelve months from the date such data was generated. Notwithstanding the above and without limitation to Section 6, we shall have no liability whatsoever for any damage, liabilities, losses (including any loss of data or profits) or any other consequences that You may incur with respect to loss of data associated with Your account and data therein.</p>

    <h2>2. Term, Termination and Suspension</h2>
    <p>2.1 The term ("Term") of this Agreement will commence once You accept this Agreement as provided above. The Agreement will remain in effect until terminated by You or Business Goals in accordance with this Section 1.</p>
    <p>2.2 You may terminate this Agreement for any reason or no reason at all, at Your convenience, by closing Your account for any Service for which we provide an account closing mechanism.</p>
    <p>2.3 We may suspend Your right and license to use any or all Services or terminate this Agreement in its entirety (and, accordingly, cease providing all Services to You), for any reason or for no reason, at our discretion at any time by providing You thirty (30) days' advance notice in accordance with the notice provisions set forth in Section 8 below. If Business Goals determines that providing advance notice would negatively impact Business Goals's ability to provide Services, Business Goals may suspend Your right and license to use any or all Services or terminate this Agreement in its entirety (and, accordingly, cease providing all Services to You), with no notice.</p>
    <p>2.4 We may suspend Your right and license to use the Service or terminate this Agreement in its entirety (and, accordingly, Your right to use the Service), for cause effective as set forth below:</p>
    <p><i>**2.4.1** Immediately upon our notice to You in accordance with the notice provisions set forth in Section 8 below if (i) You violate any provision of the Acceptable Use Policy or we have reason to believe that You have violated the Acceptable Use Policy, (ii) there is an unusual spike or increase in Your use of the Services for which there is reason to believe such traffic or use is fraudulent or negatively impacting the operating capability of the Service; (iii) we determine, in our sole discretion, that our provision of any of the Services to You is prohibited by applicable law, or has become impractical or unfeasible for any legal or regulatory reason; or (iv) subject to applicable law, upon Your liquidation, commencement of dissolution proceedings, disposal of Your assets, failure to continue Your business, assignment for the benefit of creditors, or if You become the subject of a voluntary or involuntary bankruptcy or similar proceeding.</i></p>

    <p><i>**2.4.2** Immediately and without notice if You are in default of any payment obligation with respect to any of the Services or if any payment mechanism You have provided to us is invalid or charges are refused for such payment mechanism.</i></p>

    <p><i>**2.4.3** Five (5) days following our provision of notice to You in accordance with the notice provisions set forth in Section 8 below if You breach any other provision of this Agreement and fail, as determined by us, in our sole discretion, to cure such breach within such 5-day period.</i></p>
    <p>2.5 Effect of Suspension or Termination.</p>
    <p><i>**2.5.1** Upon our suspension of Your use of any Services, in whole or in part, for any reason: (i) fees will continue to accrue for any Services that are still in use by You, notwithstanding the suspension; (ii) You remain liable for all fees, charges and any other obligations You have incurred through the date of suspension with respect to the Services; and (iii) all of Your rights with respect to the Services shall be terminated during the period of the suspension.</i></p>

    <p><i>**2.5.2** Upon termination of this Agreement for any reason: (i) You remain liable for all fees, charges and any other obligations You have incurred through the date of termination with respect to the Services; and (ii) all of Your rights under this Agreement shall immediately terminate.</i></p>
    <p>2.6 In the event this Agreement expires or is cancelled or terminated for any reason, Sections 5, 6, 7, 8, 9, and 10, and any applicable definitions will survive any such expiration, cancellation or termination.</p>
    <p>2.7 Following the suspension or termination of Your right to use the Services by us or by You for any reason other than a termination for cause, You shall be entitled to take advantage of any post-termination assistance we may generally elect to make available with respect to the Services such as data retrieval arrangements. We may also endeavor to provide You with unique post-suspension or post-termination assistance, but we shall be under no obligation to do so. Your right to take advantage of any such assistance, whether generally made available with respect to the Services or made available uniquely to You, shall be conditioned upon Your acceptance of and compliance with any fees and terms we specify for such assistance.</p>
    <h2>3. Downtime and Service Suspensions; Security</h2>
    <p>3.1 In addition to our rights to terminate or suspend Services to You as described in Section 1 above, You acknowledge that: (i) Your access to and use of the Services may be suspended for the duration of any unanticipated or unscheduled downtime or unavailability of any portion or all of the Services for any reason, including as a result of power outages, system failures or other interruptions; and (ii) we shall also be entitled, without any liability to You, to suspend access to any portion or all of the Services at any time, on a Service-wide basis: (a) for scheduled downtime to permit us to conduct maintenance or make modifications to any Service; (b) in the event of a denial of service attack or other attack on the Service or other event that we determine, in our sole discretion, may create a risk to the applicable Service, to You or to any of our other customers if the Service were not suspended; or (c) in the event that we determine that any Service is prohibited by applicable law or we otherwise determine that it is necessary or prudent to do so for legal or regulatory reasons (collectively, "Service Suspensions"). Without limitation to Section 6, we shall have no liability whatsoever for any damage, liabilities, losses (including any loss of data or profits) or any other consequences that You may incur as a result of any Service Suspension. To the extent we are able, we will endeavor to provide You notice of any Service Suspension in accordance with the notice provisions set forth in Section 8 below and to post updates regarding resumption of Services following any such suspension, but shall have no liability for the manner in which we may do so or if we fail to do so.</p>
    <h2>4. Fees</h2>
    <p>4.1 To the extent the Services or any portion thereof are made available for any fee, You agree to pay all applicable fees (including any minimum subscription fees). We may increase or add new fees for any existing Service or Service feature by giving You 30 days' advance notice. All fees payable by You are exclusive of applicable taxes and duties, including, without limitation, VAT and applicable sales tax. You will provide such information to us as reasonably required to determine whether we are obligated to collect VAT from You. You are responsible for any charges imposed on Business Goals by a carrier related to government fees for telecommunications, including but not limited to, Universal Service Fund fees, if applicable.</p>
    <p>4.2 We may specify the manner in which You will pay any fees, and any such payment shall be subject to our general accounts receivable policies from time to time in effect. All amounts payable by You under this Agreement will be made without setoff or counterclaim and without deduction or withholding. If any deduction or withholding is required by applicable law, You shall notify us and shall pay such additional amounts to us as necessary to ensure that the net amount that we receive, after such deduction and withholding, equals the amount we would have received if no such deduction or withholding had been required. Additionally, You shall provide us with documentation that the withholding and deducted amounts have been paid to the relevant taxing authority.</p>
    <p>4.3 Should You have any dispute as to fees associated with Your account, please contact us at support@buisnessgoals.org within 90 days of the date of the activity that generated such dispute, and we will attempt to resolve the matter. Any and all refunds issued to resolve such a dispute shall be issued as credits to Your account, but in no event shall there be any cash refunds. Disputes older than 90 days shall not be entitled to any refunds.</p>
    <h2>5. Intellectual Property</h2>
    <p>5.1 Other than the limited use and access rights and licenses expressly set forth in this Agreement, we reserve all right, title and interest (including all intellectual property and proprietary rights) in and to: (i) the Services; (ii) the Business Goals Properties; (iii) the Business Goals Marks; and (iv) any other technology and software that we provide or use to provide the Services and the Business Goals Properties. You do not, by virtue of this Agreement or otherwise, acquire any ownership interest or rights in the Services, the Business Goals Properties, the Business Goals Marks, or such other technology and software, except for the limited use and access rights described in this Agreement.</p>
    <p>5.2 Business Goals may, at its discretion, offer certain Software Development Kits, tools, application samples, or documentation under an open source license. Any such products will be marked with copyright details, and those copyrights will apply to those and only those documents. Business Goals reserves all rights to any documents, tools, services, technologies and the like not designated with an open license.</p>
    <p>5.3 Other than the rights and interests expressly set forth in this Agreement and excluding any and all works derived from Business Goals Properties, You reserve all right, title and interest (including all intellectual property and proprietary rights) in and to: (i) content and data You may send to us or use as part of Your use of any Services ("Your Content"); and (ii) Your Applications.</p>
    <p>5.4 In the event You elect, in connection with any of the Services, to communicate to us suggestions for improvements to the Services, the Business Goals Properties or the Business Goals Marks (collectively, "Feedback"), we shall own all right, title, and interest in and to the same, even if You have designated the Feedback as confidential, and we shall be entitled to use the Feedback without restriction. Furthermore, any other content or information You post or provide to Business Goals via comments, forums, emails and the like (collectively, "Communications") shall be considered the property of Business Goals. You hereby irrevocably assign all right, title and interest in and to the Feedback and Communications to us and agree to provide us such assistance as we may require to document, perfect, and maintain our rights to the Feedback and Communications.</p>
    <p>5.5 During and after the Term of the Agreement, with respect to any of the Services that You elect to use, You will not assert, nor will You authorize, assist, or encourage any third party to assert, against us or any of our customers, end users, vendors, business partners (including third party sellers on websites operated by or on behalf of us), sub-licensees or transferees, any patent infringement or other intellectual property infringement claim with respect to such Services.</p>
    <p>5.6 Business Goals respects the intellectual property of others, and we ask our users to do the same. If You believe that Your work has been copied in a way that constitutes copyright infringement, or that Your intellectual property rights have been otherwise violated, You should notify Business Goals of Your infringement claim in accordance with the procedure set forth below.
      Business Goals will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws with respect to any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to Business Goals’s Copyright Agent at support@buisnessgoals.org for (Subject line: "DMCA Takedown Request").</p>
    To be effective, the notification must be in writing and contain the following information:
    an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;
    a description of the copyrighted work or other intellectual property that You claim has been infringed;
    a description of where the material that You claim is infringing is located on the Site, with enough detail that we may find it on the Site;
    Your address, telephone number, and email address;
    a statement by You that You have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law;
    a statement by You, made under penalty of perjury, that the above information in Your Notice is accurate and that You are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner‘s behalf.

    <h2>6. Representations and Warranties; Disclaimers; Limitations of Liability</h2>
    <p>6.1 You represent and warrant that You will not use the Website (including our forums and comments sections), Services, Business Goals Properties, Business Goals Marks, Your Application or Your Content in a manner that violates the Acceptable Use Policy. To this effect, we ask that You take reasonable precautions to promote best practices. Although Business Goals does not assume the duty or obligation to monitor any materials created, posted or uploaded by You or any third parties, Business Goals reserves the right, in its sole and absolute discretion, to monitor any and all materials posted or uploaded by You or any third parties at any time without prior notice to ensure that they conform to any usage guidelines or policies (including our Acceptable Use Policy) relating to our Website or Services.</p>
    <p>6.2 You represent and warrant: (i) that You are solely responsible for the development, operation, and maintenance Your Content, including without limitation, the accuracy, appropriateness and completeness of Your Content and all product-related materials and descriptions; (ii) that You have the necessary rights and licenses, consents, permissions, waivers and releases to use and display Your Content; (iii) that neither Your Content (a) violates, misappropriates or infringes any rights of us or any third party, (b) constitutes defamation, invasion of privacy or publicity, or otherwise violates any rights of any third party, or (c) is designed for use in any illegal activity or promotes illegal activities, including, without limitation, in a manner that might be libelous or defamatory or otherwise malicious, illegal or harmful to any person or entity, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age; (iv) that neither Your Content contains any harmful components; and (v) to the extent to which You use any of the Business Goals Marks, that You will conduct Your business in a professional manner and in a way that reflects favorably on the goodwill and reputation of Business Goals. You also represent and warrant that You are responsible for any charges incurred by virtue of Your use of the Application, no matter whether the Application acted in error.</p>
    <p>6.3 You represent and warrant that You have read and understood the Acceptable Use Policy, Privacy Policy and You agree to abide by their terms, where applicable, including as incorporated by reference herein. You further agree to abide by all applicable local, state, national, foreign and international laws and regulations and that You will be solely responsible for all acts or omissions that occur under Your account or password, including the content of Your transmissions through the Service. By way of example, and not as a limitation, You agree not to violate any provisions of the Acceptable Use Policy.</p>
    <p>6.4 You represent and warrant that without Business Goals’s express written consent You will not use, and will not authorize any third party to use, any Public Software (as defined below) in connection with the Services in any manner that requires, pursuant to the license applicable to such Public Software, that any Business Goals Properties or Services be (a) disclosed or distributed in source code form, (b) made available free of charge to recipients, or (c) modifiable without restriction by recipients. With respect to any Feedback or Communications, You represent and warrant that such Feedback and Communications, in whole or in part, contributed by or through You, (i) is legally distributable by You, either because You own the copyright or because You have fully complied with any copyright terms associated with the software or content, (ii) contains no third party software or any software that may be considered Public Software and (iii) does not violate, misappropriate or infringe any intellectual property rights of any third party. "Public Software" means any software, documentation or other material that contains, or is derived (in whole or in part) from, any software, documentation or other material that is distributed as free software, open source software (e.g., Linux) or similar licensing or distribution models, including, but not limited to software, documentation or other material licensed or distributed under any of the following licenses or distribution models, or licenses or distribution models similar to any of the following: (i) GNU's General Public License (GPL), Lesser/Library GPL (LGPL), or Free Documentation License, (ii) The Artistic License (e.g., PERL), (iii) the Mozilla Public License, (iv) the Netscape Public License, (v) the Sun Community Source License (SCSL), (vi) the Sun Industry Standards License (SISL), (vii) the BSD License and (viii) the Apache License.</p>
    <p>6.5 You represent and warrant that: (i) the information You provide in connection with Your registration for the Services is accurate and complete; (ii) You are duly authorized to do business in the jurisdiction where You operate; and (iii) You are an authorized representative of Your entity duly authorized to access the Services and to legally bind You to this Agreement and all transactions conducted under Your account.</p>
    <p>6.6 BUSINESS GOALS PROPERTIES, THE BUSINESS GOALS MARKS, THE SERVICES AND ALL TECHNOLOGY, SOFTWARE, FUNCTIONS, CONTENT, IMAGES, MATERIALS AND OTHER DATA OR INFORMATION PROVIDED BY US OR OUR LICENSORS IN CONNECTION THEREWITH (COLLECTIVELY THE "SERVICE OFFERINGS") ARE PROVIDED "AS IS." WE AND OUR LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE BUSINESS GOALS PROPERTIES, THE BUSINESS GOALS MARKS, THE SERVICES OR THE PROMOTIONAL CREDITS. EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, WE AND OUR LICENSORS DISCLAIM ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE. WE AND OUR LICENSORS DO NOT WARRANT THAT THE SERVICES OR WEBSITE WILL FUNCTION AS DESCRIBED, WILL BE UNINTERRUPTED OR ERROR FREE, OR FREE OF HARMFUL COMPONENTS, OR THAT THE DATA YOU STORE WITHIN THE SERVICES WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED. WE AND OUR LICENSORS SHALL NOT BE RESPONSIBLE FOR ANY SERVICE OR WEBSITE INTERRUPTIONS, INCLUDING, WITHOUT LIMITATION, POWER OUTAGES, SYSTEM FAILURES OR OTHER INTERRUPTIONS. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM US OR FROM ANY THIRD PARTY OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.</p>
    <p>6.7 NEITHER WE NOR ANY OF OUR LICENSORS SHALL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) IN CONNECTION WITH THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, ANY SUCH DAMAGES RESULTING FROM: (i) THE USE OR THE INABILITY TO USE BUSINESS GOALS PROPERTIES, THE BUSINESS GOALS MARKS, THE SERVICES OR PROMOTIONAL CREDITS; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES; OR (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT. IN ANY CASE, OUR AGGREGATE LIABILITY UNDER THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU TO US HEREUNDER FOR THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
    <p>6.8 Third Party Materials: Certain content, products, and services available via the Business Goals website (or links contained therein) may include materials, software, plug-ins, applications and other resources from third parties and access to third party websites (collectively "Third Party Materials"). You acknowledge and agree that Business Goals is not responsible for examining or evaluating the content or accuracy of any such Third Party Materials and that Business Goals does not warrant or endorse and does not assume (and will not have) any liability or responsibility for any Third Party Materials or any damage or loss resulting therefrom. The availability of Third Party Materials is provided solely as a convenience to you. You agree that you must evaluate, and bear all risks associated with, the use of any Third Party Materials, including any reliance on the accuracy, completeness, or usefulness thereof. Please also remember that all use of the Business Goals website and service is subject to the Business Goals Terms of Service.</p>
    <p>6.9 You represent to Business Goals that you are and will continue to be in compliance with all applicable privacy laws. You further represent that you have obtained all necessary rights and consents under applicable law to disclose to Business Goals - or allow Business Goals to collect, use, retain, and disclose - any Data that you provide to us or authorize us to collect, including information that we may collect directly from your end users via cookies or other means. As between you and Business Goals, you are solely responsible for disclosing to your customers that Business Goals is processing credit card transactions for you and obtaining Data from such customers.</p>
    <h2>7. Indemnification</h2>
    <p>7.1 You agree to indemnify, defend and hold us, our affiliates and licensors, each of our and their business partners (including third party sellers on websites operated by or on behalf of us) and each of our and their respective employees, officers, directors and representatives, harmless from and against any and all claims, losses, damages, liabilities, judgments, penalties, fines, costs and expenses (including reasonable attorneys fees), arising out of or in connection with any claim arising out of (i) Your use of the Services, Business Goals Properties and/or Business Goals Marks in a manner not authorized by this Agreement, and/or in violation of the applicable restrictions, the Acceptable Use Policy, and/or applicable law, (ii) Your Application, Your Content, or the combination of either with other applications, content or processes, including but not limited to any claim involving infringement or misappropriation of third-party rights and/or the use, development, design, manufacture, production, advertising, promotion and/or marketing of Your Application and/or Your Content, (iii) Your violation of any term or condition of this Agreement, the Acceptable Use Policy or any applicable additional policies, including without limitation, Your representations and warranties, or (iv) You or Your employees' or personnel's negligence or willful misconduct.</p>
    <p>7.2 We agree to promptly notify You of any claim subject to indemnification; provided that our failure to promptly notify You shall not affect Your obligations hereunder except to the extent that our failure to promptly notify You delays or prejudices Your ability to defend the claim. At our option, You will have the right to defend against any such claim with counsel of Your own choosing (subject to our written consent) and to settle such claim as You deem appropriate, provided that You shall not enter into any settlement without our prior written consent and provided that we may, at any time, elect to take over control of the defense and settlement of the claim.</p>
    <h2>8. Disputes</h2>
    <p>8.1 Notwithstanding anything to the contrary, we may seek injunctive or other relief in any state, federal, or national court of competent jurisdiction for any actual or alleged infringement of Business Goals’s or any third party's intellectual property rights and/or proprietary rights. You further acknowledge that our rights in Business Goals’s Services, Business Goals Properties and the Business Goals Marks are of a special, unique, extraordinary character, giving them peculiar value, the loss of which cannot be readily estimated and may not be adequately compensated for in monetary damages.</p>
    <p>8.2 By using the Services, You agree that the laws of the State of California, without regard to principles of conflicts of laws, will govern this Agreement and any dispute of any sort that might arise between You and us.</p>
    <p>8.3 Arbitration. YOU AND BUSINESS GOALS BOTH AGREE TO RESOLVE DISPUTES ONLY BY ARBITRATION. THERE'S NO JUDGE OR JURY IN ARBITRATION, AND THE PROCEDURES MAY BE DIFFERENT, BUT AN ARBITRATOR CAN AWARD YOU THE SAME DAMAGES AND RELIEF, AND MUST HONOR THE SAME TERMS IN THIS AGREEMENT, AS A COURT WOULD. IF THE LAW ALLOWS FOR AN AWARD OF ATTORNEYS' FEES, AN ARBITRATOR CAN AWARD THEM TOO. YOU AND BUSINESS GOALS ALSO BOTH AGREE THAT:</p>
    <p><i>**8.3.1** THE FEDERAL ARBITRATION ACT APPLIES TO THIS AGREEMENT. ANY DISPUTE THAT IN ANY WAY RELATES TO OR ARISES OUT OF THIS AGREEMENT OR FROM ANY SERVICES YOU RECEIVE FROM US WILL BE RESOLVED BY ONE OR MORE NEUTRAL ARBITRATORS BEFORE THE AMERICAN ARBITRATION ASSOCIATION ("AAA"). YOU CAN ALSO BRING ANY ISSUES YOU MAY HAVE TO THE ATTENTION OF FEDERAL, STATE, OR LOCAL GOVERNMENT AGENCIES, AND IF THE LAW ALLOWS, THEY CAN SEEK RELIEF AGAINST US FOR YOU.</i></p>

    <p><i>**8.3.2** UNLESS YOU AND BUSINESS GOALS AGREE OTHERWISE, THE ARBITRATION WILL TAKE PLACE IN ROSEVILLE, CALIFORNIA. YOU CAN GET PROCEDURES, RULES AND FEE INFORMATION FROM THE AAA (WWW.ADR.ORG) OR FROM US.</i></p>

    <p><i>**8.3.3** THIS AGREEMENT DOESN'T ALLOW CLASS OR COLLECTIVE ARBITRATIONS EVEN IF THE AAA PROCEDURES OR RULES WOULD. NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, THE ARBITRATOR MAY AWARD MONEY OR INJUNCTIVE RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT PARTY'S INDIVIDUAL CLAIM. NO CLASS OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL THEORIES OF LIABILITY OR PRAYERS FOR RELIEF MAY BE MAINTAINED IN ANY ARBITRATION HELD UNDER THIS AGREEMENT.</i></p>

    <p><i>**8.3.4** IF EITHER YOU OR BUSINESS GOALS INTENDS TO SEEK ARBITRATION UNDER THIS AGREEMENT, THE PARTY SEEKING ARBITRATION MUST FIRST NOTIFY THE OTHER PARTY OF THE DISPUTE IN WRITING AT LEAST 30 DAYS IN ADVANCE OF INITIATING THE ARBITRATION. NOTICE TO BUSINESS GOALS SHOULD BE SENT AS REQUIRED BY THIS AGREEMENT. THE NOTICE MUST DESCRIBE THE NATURE OF THE CLAIM AND THE RELIEF BEING SOUGHT. IF YOU AND BUSINESS GOALS ARE UNABLE TO RESOLVE OUR DISPUTE WITHIN 30 DAYS, EITHER PARTY MAY THEN PROCEED TO FILE A CLAIM FOR ARBITRATION.</i></p>

    <p><i>**8.3.5** AN ARBITRATION AWARD AND ANY JUDGMENT CONFIRMING IT APPLY ONLY TO THAT SPECIFIC CASE; IT CAN'T BE USED IN ANY OTHER CASE EXCEPT TO ENFORCE THE AWARD ITSELF.</i></p>

    <p><i>**8.3.6** IF FOR SOME REASON THE PROHIBITION ON CLASS ARBITRATIONS SET FORTH IN SUBSECTION (3) CANNOT BE ENFORCED, THEN THE AGREEMENT TO ARBITRATE WILL NOT APPLY.</i></p>

    <p><i>*8.3.7** IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN THROUGH ARBITRATION, YOU AND BUSINESS GOALS AGREE THAT THERE WILL NOT BE A JURY TRIAL. YOU AND BUSINESS GOALS UNCONDITIONALLY WAIVE ANY RIGHT TO TRIAL BY JURY IN ANY ACTION, PROCEEDING OR COUNTERCLAIM ARISING OUT OF OR RELATING TO THIS AGREEMENT IN ANY WAY. IN THE EVENT OF LITIGATION, THIS PARAGRAPH MAY BE FILED TO SHOW A WRITTEN CONSENT TO A TRIAL BY THE COURT. YOU AND BUSINESS GOALS ALSO AGREE THAT ANY DISPUTE HEREUNDER SHALL BE ADJUDICATED IN ANY STATE OR FEDERAL COURT IN ROSEVILLE, CALIFORNIA, AND YOU CONSENT TO EXCLUSIVE JURISDICTION AND VENUE IN SUCH COURTS.</i></p>
    <h2>9. Notices</h2>
    <p>9.1 Notices made by us under this Agreement for You or Your account specifically (e.g., notices of breach and/or suspension) will be provided to You via a notification message displayed on Your account page or via the email address provided to us in Your registration for the Services or in any updated email address You provide to us in accordance with standard account information update procedures we may provide from time to time. It is Your responsibility to keep Your email address current and You will be deemed to have received any email sent to any such email address, upon our sending of the email, whether or not You actually receive the email.</p>
    <p>9.2 For notices made by You to us under this Agreement and for questions regarding this Agreement or the Services, You may contact Business Goals at support@buisnessgoals.org.</p>
    <h2>10. Miscellaneous Provisions</h2>
    <p>10.1 Responsibility. If You authorize, assist, encourage or facilitate another person or entity to take any action related to the subject matter of this Agreement, You shall be deemed to have taken the action Yourself.</p>
    <p>10.2 Severability. If any portion of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, the remaining portions of this Agreement will remain in full force and effect, and any invalid or unenforceable portions shall be construed in a manner that most closely reflects the effect and intent of the original language. If such construction is not possible, the provision will be severed from this Agreement, and the rest of the Agreement shall remain in full force and effect.</p>
    <p>10.3 Waivers. The failure by us to enforce any provision of this Agreement shall in no way be construed to be a present or future waiver of such provision nor in any way affect our right to enforce such provision thereafter. All waivers by us must be in writing to be effective.</p>
    <p>10.4 Successors and Assigns. This Agreement will be binding upon, and inure to the benefit of the parties and their respective successors and assigns.</p>
    <p>10.5 Entire Agreement. This Agreement incorporates by reference all policies and guidelines posted on the Business Goals Website and as may be modified thereafter (including the Acceptable Use Policy and the Privacy Policy) and constitutes the entire agreement between You and us regarding the subject matter hereof and supersedes any and all prior or contemporaneous representation, understanding, agreement, or communication between You and us, whether written or oral, regarding such subject matter.</p>
    <p>10.6 No Endorsement. You understand and acknowledge that we are not certifying nor endorsing, and have no obligation to certify or endorse, Your Business using these Services.</p>
    <p>10.7 No Agency. Nothing in this Agreement shall be construed as creating a partnership, contract of employment, agency, joint venture or franchise relationship between Business Goals with you.</p>
    <p>10.9 No Third Party Beneficiary. You acknowledge and agree that, except as otherwise expressly provided in the Terms, there shall be no third party beneficiary to this agreement.</p>
  </div>
}

export default TermsOfService;