import React, { useContext, useEffect, useState } from 'react'
import './CreateUserPage.scss'

import OrganizationController from '../../Phoenix/Controller/OrganizationController';
import PhoenixContext from '../../Phoenix/PhoenixContext';
import { Link, useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in';

import backButtonImage from '../../backbutton.png';
import UserController from '../../Phoenix/Controller/UserController';
import { Modal } from 'react-bootstrap';

export default function CreateUserPage(props: { loggedInUser: any }) {
  const history = useHistory();

  /* Selectables State */
  const [teamOptions, setTeamOptions] = useState<any[]>([]);
  const [managerOptions, setManagerOptions] = useState<any[]>([]);

  /* User Info State */
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [reportsTo, setReportsTo] = useState<number>(-1);
  const [team, setTeam] = useState<number>(props.loggedInUser.team);
  const [globalAdmin, setGlobalAdmin] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const [showUserCreatedModal, setShowUserCreatedModal] = useState(false);
  const [createdUserId, setCreatedUserId] = useState(-1);

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);
  const userController = new UserController(phoenix);

  const loadOrgTree = async () => {
    const response = await organizationController.getOrgTree();
    if (response.body !== undefined) {
      const orgTree = response.body.orgTree;
      let options: any[] = organizationController.convertOrgTreeToTeamOptionsList(orgTree);

      setTeamOptions(options);
      setTeam(orgTree.id);
    } else {
      setError(response.error || "Something went wrong.")
    }
  }

  const loadUserTree = async () => {
    const response = await userController.getUserTree();
    if (response.body !== undefined) {
      const topUsers = response.body.topUsers;
      let options: any[] = userController.convertUserTreeToOptionsList(topUsers);

      setManagerOptions(options);
    } else {
      setError(response.error || "Something went wrong.")
    }
  }

  useEffect(() => {
    loadOrgTree();
    loadUserTree();
    // eslint-disable-next-line
  }, []);

  const createUser = async() => {
    clearError();

    if (name.length === 0) {
      return setError("You can't leave your name blank.")
    }

    if (email.length === 0) {
      return setError("You can't leave your email blank.")
    }

    const response = await userController.createUser({
      name: name,
      email: email,
      role: role,
      reportsTo: reportsTo,
      team: team,
      globalAdmin: globalAdmin
    });

    if (response.error !== undefined) {
      setError(response.error)
    } else {
      setCreatedUserId(response.body.user.id);
      setShowUserCreatedModal(true);
    }
  }

  const clearError = () => {
    setError(null);
  }

  const clear = () => {
    setShowUserCreatedModal(false)
    setCreatedUserId(-1);

    setName("")
    setEmail("")
    setRole("")
    setTeam(props.loggedInUser.topLevelOrg)
    setGlobalAdmin(false)
    setReportsTo(-1)
  }

  return <div className="container create-user-page">
    <FadeIn>
      <Link to={"/admin"} style={{
        fontWeight: 650
      }}>
        <img src={backButtonImage} alt="Back Button" style={{ height: 20, marginRight: 5 }} />Back to Admin Portal
      </Link>
      <Modal show={showUserCreatedModal}>
        <Modal.Header>
          <h3>User Created!</h3>
        </Modal.Header>
        <Modal.Body>
          <p>Would you like to create another user?</p>
        </Modal.Body>
        <div className="button-holder" style={{gridTemplateColumns: "auto auto"}}>
            <button type="button" className="btn" onClick={(e) => {
              history.push(`/admin/user/${createdUserId}`)
            }} style={{backgroundColor: '#5A5A5A'}}>View Created User</button>
            <button type="button" className="btn" style={{backgroundColor: '#167efb'}} onClick={(e) => {
              clear();
              loadOrgTree();
              loadUserTree();
            }}>Create Another User</button>
        </div>
      </Modal>
      <form>
        {error !== null ? <div>
          <p className="error-box">{error}</p>
        </div> : null}

        <h2 className="title">Create User</h2>
        <div className="form-group">
          <label>Name</label>
          <input className="form-control"
            type="text"
            placeholder="John Appleseed"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              clearError()
            }} />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input className="form-control"
            type="text"
            placeholder="john.appleseed@businessgoals.org"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              clearError()
            }} />
        </div>

        <div className="form-group">
          <label>Role</label>
          <input className="form-control"
            type="text"
            placeholder="Role"
            value={role}
            onChange={(e) => {
              setRole(e.target.value)
              clearError()
            }} />
        </div>

        <div className="form-group">
          <label>Reports To</label>
          {managerOptions.length === 0 ? <div>
            <p>No manager options available.</p>
          </div> : <select className="form-control"
            value={reportsTo}
            onChange={(e) => {
              e.preventDefault();
              setReportsTo(parseInt(e.target.value))
            }}>
              <option value="-1">Nobody</option>
              {managerOptions.map((row) => {
                return <option value={row.id} key={row.id}>{row.name}</option>
              })}
            </select>}
        </div>

        <div className="form-group">
          <label>Team</label>
          {teamOptions.length === 0 ? <div>
            <p>No team options available.</p>
          </div> : <select className="form-control"
            value={team}
            onChange={(e) => {
              e.preventDefault();
              setTeam(parseInt(e.target.value))
              clearError()
            }}>
              {teamOptions.map((row) => {
                return <option value={row.id} key={row.id}>{row.name}</option>
              })}
            </select>}
        </div>

        <div className="form-group">
          <label>Permission Level</label>
          <select
            value={`${globalAdmin}`}
            className="form-control"
            onChange={(e) => {
              e.preventDefault();
              setGlobalAdmin(e.target.value === "true")
              clearError()
            }}>
            <option value="true">Global Administrator</option>
            <option value="false">No Permissions</option>
          </select>
        </div>

        <div>
          <button type="button" className="btn" onClick={(e) => {
            e.preventDefault();

            clear();
          }} style={{ backgroundColor: '#5A5A5A' }}>Clear</button>
          <button type="button" 
            className="btn" 
            style={{ backgroundColor: '#167efb' }}
            onClick={(e) => {
              e.preventDefault();
              createUser();
            }}>Save</button>
        </div>
      </form>
    </FadeIn>
  </div>;
}