import React, { useContext, useEffect, useState } from 'react'

import UserRow from '../UserRow/UserRow';

import './OrganizationPage.css';
import PhoenixContext from '../Phoenix/PhoenixContext';
import OrganizationController from '../Phoenix/Controller/OrganizationController';
import DepartmentRow from '../Consumer/TeamPage/DepartmentRow/DepartmentRow';
import GoalView from '../Consumer/GoalView';
import OrgNotesModal from '../Consumer/OrgNotesModal';

import Page from '../common/Page/Page';
import PageSectionObject from '../common/Page/PageSectionObject';

import BackIcon from '../Back128.png'
import GoalIcon from '../image/icon/Goal128.png'
import TeamIcon from '../image/icon/TeamIcon.png'
import DepartmentsIcon from '../image/icon/DepartmentsIcon.png'
import { Link } from 'react-router-dom';

export default function OrganizationPage(props) {
  const user = props.loggedInUser;
  const teamId = props.match.params.teamId;

  const [organization, setOrganization] = useState(null);
  const [error, setError] = useState(null);

  const [showNotesModal, setShowNotesModal] = useState(false);

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    async function loadOrganization() {
      const response = await organizationController.getOrganization(teamId);
      if (response.body !== undefined) {
        setOrganization(response.body.organization);
      } else {
        setError(response.error);
      }
    }

    loadOrganization();
    // eslint-disable-next-line
  }, [teamId]);

  if (error !== null) {
    return <div className="container">
      {error}
    </div>
  }

  if (organization === null) {
    return <div className="container">
      <p>Loading...</p>
    </div>;
  }

  const membersSize = organization.members.length;
  const childrenSize = organization.children.length;

  return <div className="container teamPage">
    <div>
      <OrgNotesModal
        organization={organization}
        loggedInUser={user}
        show={showNotesModal}
        hideCallback={() => {
          setShowNotesModal(false);
        }} />

      <Link to="/admin" style={{fontWeight: 600}}>
        <img src={BackIcon} alt="Back to Admin Portal" style={{
          width: 20,
          height: 20
        }} /> Back to Administration Portal
      </Link>

      <Page pageTitle={organization.name}
        pageSubtitle={
          `${membersSize} Member${membersSize === 1 ? "" : "s"}${childrenSize !== 0 ? " • " + childrenSize + " Department" + (childrenSize === 1 ? "" : "s") : ""}`
        }
        showBackButton={true}
        sections={[
          new PageSectionObject("Team Members", TeamIcon, organization.members, [], (member, searchText) => {
            if (searchText === undefined) return true;
            return member.name.toLowerCase().includes(searchText.toLowerCase());
          }, (user) => {
            return <UserRow user={user} key={user.id} isAdminContext={true} />
          }),
          new PageSectionObject("Departments", DepartmentsIcon, organization.children, [], (department, searchText) => {
            if (searchText === undefined) return true;
            return department.name.toLowerCase().includes(searchText.toLowerCase());
          }, (department) => {
            return <DepartmentRow department={department} key={department.id} linkLocation={`/admin/team/${department.id}`} />
          }),
          new PageSectionObject("Goals", GoalIcon, organization.goals, [], (goal, searchText) => {
            if (searchText === undefined) return true;
            return goal.title.toLowerCase().includes(searchText.toLowerCase());
          }, (goal) => {
            return <GoalView goal={goal} key={goal.id} />;
          })
        ]} />
    </div>
  </div>;
}
