import React, { useState } from 'react'
import PageHeader from './PageHeader';
import PageSectionObject from './PageSectionObject';

export default function PageSection(props: {section: PageSectionObject, reference?: any}) {
  const [searchText, setSearchText] = useState<string>("");

  const section = props.section;
  return <div key={section.name} ref={props.reference} className="pageSection" id={section.toHTMLId()}>
    <PageHeader title={section.name} onTextChange={(text) => {
      setSearchText(text)
    }} actions={section.actions} />

    <div className="content">
      {section.content.filter((content: any) => {
        return section.filterProvider(content, searchText);
      }).map((content: any) => {
        return section.contentComponentProvider(content);
      })}
    </div>
  </div>
}