import React, { useContext, useEffect, useState } from 'react'
import CreateGoalModal from './CreateGoalModal.js'
import useReactRouter from 'use-react-router';

import GoalView from '../GoalView.js'

import NotesImage from '../Notes128.png'

import UserRow from '../../UserRow/UserRow';

import OrgNotesModal from '../OrgNotesModal.js'

import SmallTaskRow from './SmallTaskRow.js';
import PhoenixContext from '../../Phoenix/PhoenixContext.js'
import OrganizationController from '../../Phoenix/Controller/OrganizationController';

import Page from '../../common/Page/Page'
import PageSectionObject, { PageSectionAction } from '../../common/Page/PageSectionObject'

import DepartmentRow from './DepartmentRow/DepartmentRow'

import GoalIcon from '../../image/icon/Goal128.png'
import PlusCircle from '../../PlusCircle.png'
import CheckInsIcon from '../CheckIns128.png'
import TeamIcon from '../../image/icon/TeamIcon.png'
import DepartmentsIcon from '../../image/icon/DepartmentsIcon.png'

import './TeamPage.scss';

import moment from "moment"
import { Link } from 'react-router-dom';

enum WeekType {
  LAST, THIS, NEXT
}

export default function TeamPage(props: any) {
  const user = props.loggedInUser;
  const teamId = props.match.params.teamId;

  const phoenix = useContext(PhoenixContext);
  const organizationController = new OrganizationController(phoenix);

  const [loading, setLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showCreateGoalModal, setShowCreateGoalModal] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);

  const { history } = useReactRouter();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    async function load() {
      const response = await organizationController.getOrganization(teamId);
      if (response.body !== undefined && response.body.organization !== undefined) {
        setOrganization(response.body.organization);
      } else {
        if (response.error !== undefined) {
          setError(response.error);
        } else {
          setError("Failed to load... something went wrong.")
        }
      }

      setLoading(false);
    };

    load();
    // eslint-disable-next-line
  }, [teamId]);

  if (loading) return null;
  if (error !== null) {
    return <div className="container">
      {error}
    </div>
  }
  if (organization === null) {
    return <div className="container">
      <h2>Error</h2>
      <p>We couldn't find that team. Please try again, or contact support.</p>
      <Link to="/">Go Home</Link>
    </div>
  }

  const membersSize = organization.members.length;
  const childrenSize = organization.children.length;

  const isLoggedInUserOrgAdmin = organization.administrators.filter((admin: any) => {
    return admin.id === user.id
  }).length === 1;

  const goalsActions = []
  if (user.isGlobalAdmin || isLoggedInUserOrgAdmin) {
    goalsActions.push(
      new PageSectionAction(PlusCircle, "New Goal", () => {
        setShowCreateGoalModal(true);
      })
    )
  }

  goalsActions.push(
    new PageSectionAction(NotesImage, "Notes", () => {
      setShowNotesModal(true)
    })
  )

  return <div className="container teamPage">
    <div>
      <CreateGoalModal
        organization={organization}
        loggedInUser={user}
        show={showCreateGoalModal}
      />

      <OrgNotesModal
        organization={organization}
        loggedInUser={user}
        show={showNotesModal}
        hideCallback={() => {
          setShowNotesModal(false);
        }} />

      <Page pageTitle={organization.name}
        pageSubtitle={
          `${membersSize} Member${membersSize === 1 ? "" : "s"}${childrenSize !== 0 ? " • " + childrenSize + " Department" + (childrenSize === 1 ? "" : "s") : ""}`
        }
        showBackButton={true}
        sections={[
          new PageSectionObject("Goals", GoalIcon, organization.goals, goalsActions, (goal: any, searchText: string) => {
            if (searchText === undefined) return true;
            return goal.title.toLowerCase().includes(searchText.toLowerCase());
          }, (goal: any): any => {
            return <GoalView goal={goal} key={goal.id} />;
          }),
          new PageSectionObject("Team Members", TeamIcon, organization.members, [
            new PageSectionAction(CheckInsIcon, "View All Check-Ins", () => {
              history.push("/team-check-ins/" + organization.id)
            })
          ], (member: any, searchText: string) => {
            if (searchText === undefined) return true;
            return member.name.toLowerCase().includes(searchText.toLowerCase());
          }, (user: any): any => {
            return <UserSection user={user} key={user.id} />
          }),
          new PageSectionObject("Departments", DepartmentsIcon, organization.children, [], (department: any, searchText: string) => {
            if (searchText === undefined) return true;
            return department.name.toLowerCase().includes(searchText.toLowerCase());
          }, (department: any): any => {
            return <DepartmentRow department={department} key={department.id} />
          })
        ]} />
    </div>
  </div>;
};

function UserSection(props: { user: any }) {
  return <div style={{ marginBottom: 15 }} className="userSection">
    <UserRow user={props.user} />

    <div className="row" style={{ width: '100%' }}>
      <WeekColumn weekType={WeekType.LAST} user={props.user} />
      <WeekColumn weekType={WeekType.THIS} user={props.user} />
      <WeekColumn weekType={WeekType.NEXT} user={props.user} />
    </div>
  </div>
}

function WeekColumn(props: { weekType: WeekType, user: any }) {
  const tasks = getTasks(props.weekType, props.user);
  let title;
  let noTasksTitle;

  switch (props.weekType) {
    case WeekType.LAST:
      title = "Last Week";
      noTasksTitle = "No tasks were due or completed last week."
      break;
    case WeekType.THIS:
      title = "This Week";
      noTasksTitle = "No tasks are due or completed this week."
      break;
    case WeekType.NEXT:
      title = "Next Week";
      noTasksTitle = "No tasks are due next week."
      break;
  }

  return <div className="col-4 weekColumn">
    <h5 style={{ fontWeight: 650 }}>{title}</h5>
    {tasks.length === 0 ? <p>{noTasksTitle}</p> : tasks.map((task: any) => {
      return <SmallTaskRow task={task} taskOwner={props.user} key={task.id} />
    })}
  </div>;
}

function getTasks(weekType: WeekType, user: any) {
  return user.recentTasks.filter((task: any) => {
    const now = moment();

    let taskDate = task.dueDate;

    if (task.completed) {
      taskDate = task.completionDate
    }

    let nowWeek;
    if (weekType === WeekType.THIS) {
      nowWeek = now.isoWeek();
    } else if (weekType === WeekType.LAST) {
      nowWeek = now.subtract("1", "week").isoWeek();
    } else {
      nowWeek = now.add("1", "week").isoWeek();
    }

    return (nowWeek === moment(taskDate).isoWeek())
  }).sort((one: any, two: any) => {
    return one.priority < two.priority
  }).sort((one: any, two: any) => {
    return one.aboveLine < two.aboveLine
  }).slice(0, 3);
}