import React from 'react'

const Tasks = require("../../MaxJS/MaxJS.js").Tasks;

export default class NewExpectationButton extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            userId: props.userId,
            onTaskCreated: props.onTaskCreated,
            creatingTask: false,
            inputContainerValue: ""
        }
    }

    render() {
        return <div className="newTaskButton">
            <div style={{display: 'inline-block', width: '70%', marginTop: 8}}>
                <input
                    id="newExpectationInput"
                    type="text"
                    placeholder="New Expectation..."
                    autoComplete="off"
                    onChange={(e) => {
                        const currentState = this.state
                        currentState.inputContainerValue = e.target.value
                        this.setState(currentState)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this.create()
                        }
                    }} />
            </div>
            <div style={{display: 'inline-block', width: '30%'}}>
                {this.state.inputContainerValue === "" ? null : <button href="#saveTask"
                    style={{
                        width: '85%',
                        marginLeft: 8,
                        backgroundColor: '#007AFF',
                        border: 'none',
                        borderRadius: 5,
                        color: 'white',
                        fontWeight: '600',
                    }}
                    onClick={(e) => {
                        e.preventDefault();

                        this.create()
                    }}>Save Task</button>}
            </div>
        </div>
    }

    create() {
        if (this.state.creatingTask || this.state.inputContainerValue === "") {
            return;
        }

        const currentState = this.state
        currentState.creatingTask = true
        this.setState(currentState)

        Tasks.createTask(this.state.inputContainerValue, this.state.userId).then((task) => {
            document.getElementById("newExpectationInput").value = "";

            const currentState = this.state
            currentState.creatingTask = false
            currentState.inputContainerValue = ""
            this.setState(currentState)

            this.state.onTaskCreated(task)
        })
    }

}
