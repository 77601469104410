import Cookies from 'universal-cookie';

const $ = require("jquery");

const cookies = new Cookies();

const isDev = window.location.hostname === "localhost";

let apiKey = "cce14c52-25f9-4118-80b6-11f018d91484";
let host = "https://server.businessgoals.org";

if (isDev) {
    apiKey = "1234"
    host = "http://localhost:3000"
}

const sessionKey = cookies.get("sessionKey");

function getSessionKey() {
  return cookies.get("sessionKey");
}

const sendRequest = (type, endpoint, data, successCallback, errorCallback) => {
    if (isDev) {
        console.time(type + " " + endpoint);
        console.log(type + " " + endpoint, `API Key ${apiKey}`)
    }

    $.ajax({
        type: type,
        url: host + endpoint,
        data: data,
        beforeSend: (request) =>  {
            request.setRequestHeader("APIKey", apiKey);
            request.setRequestHeader("SessionKey", getSessionKey());
            request.setRequestHeader("PerformanceMode", "active");
        },
        success: (results) => {
            successCallback(results);

            if (isDev) {
                console.timeEnd(type + " " + endpoint);
            }
        },
        error: (xhr, status, error) => {
            if (xhr !== undefined && xhr.hasOwnProperty('responseJSON')) {
                console.log(JSON.stringify(xhr.responseJSON));
            } else {
                console.log(JSON.stringify(xhr));
            }

            console.timeEnd(type + " " + endpoint);

            errorCallback(xhr, status, error);
        }
    })
};

const sendImageRequest = (endpoint, formData, callback) => {
    $.ajax({
         url: host + endpoint,
         type: "POST",
         data: formData,
         beforeSend: (request) =>  {
             request.setRequestHeader("APIKey", apiKey)
             request.setRequestHeader("SessionKey", sessionKey)
         },
         processData: false, // tell jQuery not to process the data
         contentType: false  // tell jQuery not to set contentType
        }).done(function(data) {
            callback(data);
        });
}

const Login = require("./Login.js");
Login.configure({sendRequest: sendRequest, cookies: cookies});

const Users = require("./Users.js");
Users.configure({sendRequest: sendRequest, sendImageRequest: sendImageRequest});

const Organizations = require("./Organizations.js");
Organizations.configure({sendRequest: sendRequest});

const Tasks = require("./Tasks.js");
Tasks.configure({sendRequest: sendRequest, sendImageRequest: sendImageRequest});

const Goals = require("./Goals.js");
Goals.configure({sendRequest: sendRequest});

export { Login, Users, Organizations, Goals, Tasks };
