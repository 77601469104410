import React from 'react';

const Tasks = require("../../../MaxJS/MaxJS.js").Tasks;
const Waiter = require("../../../Waiter.js");

export default class TaskReprioritzeModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            type: "below",
            allTasks: props.tasks || [],
            tasks: this.filterTasks(props.tasks || [], false, false),
            showSegmentedControl: true
        }

        if (this.filterTasks(props.tasks || [], false, true).length === 0) { // no above line tasks
            this.state.showSegmentedControl = false
        } else if (this.filterTasks(props.tasks || [], false, false).length === 0) { // no below line tasks
            this.state.showSegmentedControl = false
            this.state.tasks = this.filterTasks(props.tasks || [], false, true)
        }
    }

    filterTasks(tasks, showCompleted, aboveLine) {
        let filtered = tasks.filter((task) => {
            if (!showCompleted && task.completed) {
                return false
            } else if (showCompleted) {
                return task.aboveLine === aboveLine && task.completed
            } else {
                return task.aboveLine === aboveLine
            }
        })

        if (showCompleted) {
            return filtered.sort((a, b) => {
                return a.completionDate < b.completionDate
            })
        } else {
            return filtered.sort((a, b) => {
                return a.priorty - b.priorty
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentState = this.state
        currentState.allTasks = nextProps.tasks || []
        this.setState(currentState)
    }

    onDragStart(e, index) {
        this.draggedTask = this.state.tasks[index];

        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    }

    onDragOver(index) {
        const draggedOverTask = this.state.tasks[index];

        // if the item is dragged over itself, ignore
        if (this.draggedTask === draggedOverTask) {
            return;
        }

        // filter out the currently dragged item
        let tasks = this.state.tasks.filter(task => task !== this.draggedTask);

        // add the dragged item after the dragged over item
        tasks.splice(index, 0, this.draggedTask);

        tasks.forEach((task, index) => {
            task.priority = index + 1 // Update the position
        })

        const currentState = this.state
        currentState.tasks = tasks
        this.setState(currentState)
    }

    onDragEnd() {
        this.draggedTask = null;
    }

    render() {
        return (
            <div id={"taskReorderModal"} className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{backgroundColor: 'rgb(255, 255, 255)', borderRadius: 20}}>
                        <div className="modal-header">
                            <h4 className="modal-title">Reprioritze Tasks</h4>
                        </div>

                        <div className="modal-body">
                            {this.state.showSegmentedControl ? <div className="segmented" style={{marginBottom: 15}}>
                                <label id="reprioritze-seg-above" className={this.state.type === "above" ? "checked" : ""}><input type="radio" name="segmented" checked onClick={this.onSegmentedControlChanged.bind(this)} readOnly /> Above Line</label>
                                <label id="reprioritze-seg-below" className={this.state.type === "below" ? "checked" : ""}><input type="radio" name="segmented" onClick={this.onSegmentedControlChanged.bind(this)} readOnly /> Below Line</label>
                            </div> : null}

                            <p>Drag the tasks to re-position them.</p>

                            <ol>
                                {this.state.tasks.map((task, index) => {
                                    return <li key={task.id} onDragOver={() => this.onDragOver(index)}>
                                            <div draggable
                                                onDragStart={(e) => this.onDragStart(e, index)}
                                                onDragEnd={this.onDragEnd.bind(this)}>

                                                {task.title}
                                            </div>
                                        </li>
                                })}
                            </ol>
                        </div>

                        <div className="button-holder" style={{gridTemplateColumns: "auto auto"}}>
                            <button type="button" className="btn" onClick={(e) => window.location.reload()} style={{backgroundColor: '#5A5A5A'}}>Cancel</button>
                            <button type="button" className="btn" style={{backgroundColor: '#167efb'}} onClick={this.onSubmitClicked.bind(this)}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onSegmentedControlChanged(e) {
        e.preventDefault();

        const parent = e.target.parentElement;
        const tab = parent.id.split("-")[2];

        const currentState = this.state
        currentState.type = tab
        currentState.tasks = this.filterTasks(currentState.allTasks, false, tab === "above")
        this.setState(currentState)
    }

    onCancelClicked() {
        window.location.reload()
    }

    onSubmitClicked(event) {
        event.persist()
        event.preventDefault()

        event.target.innerHTML = "Saving, just a moment..."
        event.target.disabled = true

        new Waiter((task, completion) => {
            Tasks.editTask(task.id, {
                priority: task.priority
            }).then(() => {
                completion()
            }).catch((error) => {
                completion()
            })
        }).execute(this.state.tasks).then(() => {
            window.location.reload();
        })
    }

}
